
import { DateTime } from 'luxon';
import { appContext } from '../../../../AppContext';
import { DateHelper } from '../../../../Common/DateHelper';
import { IbssComponent } from '../../../../Components/Core/BaseComponent/IbssComponent';
import { Box, FormControl, Grid, MenuItem, Select, SelectChangeEvent, TextField, InputLabel, Typography } from '@mui/material';
import IbssSvgIcon from '../../../../Components/Icons/SvgIcon/IbssSvgIcon';
import { Icons } from '../../../../Common/AllsvgIcons';
import IbssButtonRedo from "../../../../Components/Buttons/Button/IbssButton";
import IbssToggleButton, { toggleButtonListObject } from '../../../../Components/Inputs/ToggleButtons/IbssToggleButtons';
import IbssFormControl from '../../../../Components/Forms/FormControl/IbssFormControl';
import IbssInputDropDown from '../../../../Components/Inputs/SelectList/IbssInputDropDown';
import IbssDatePicker from '../../../../Components/Inputs/DatePicker/IbssDatePicker';
import IbssTextField from '../../../../Components/Inputs/TextField/IbssTextField';


class IbssBookingListHeader extends IbssComponent<IProps, IState>
{
    private get labels() { return appContext().labels; }

    private startDateChanged(date: DateTime | null): void
    {
        const dateTime = (date == null ? DateHelper.null() : date );
        this.props.onStartDateChange?.(dateTime);
    }

    private endDateChanged(date: DateTime | null): void
    {
        const dateTime = (date == null ? DateHelper.null() : date );
        this.props.onEndDateChange?.(dateTime);
    }
    public render(): JSX.Element
    {
        const hasShowFilterFields = this.props.toggleButtonList?.some(item => item.value === this.props.layout && item.showFilterFields);
        const endDate = this.props.endDate ? this.props.endDate : null;
        const startDate = this.props.startDate ? this.props.startDate : null;
        const toDateFieldError = endDate && startDate ? endDate < startDate : false;
        return (
            <>
                <Grid container rowSpacing={0} sx={{ display: 'flex', alignItems: 'center', mt: 0, ml: 0 }}>
                    <Grid item lg={12} sx={{ pt: 0, display: 'flex', alignItems: 'center' }}>
                        <Box className="table-panel-header" component="div" sx={{ ml: '0', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', width: "100%" }}>
                            {hasShowFilterFields ? (
                                <Box sx={{ display: "flex", gap: "20px" }}>
                                    <IbssSvgIcon sx={{ color: (theme) => theme.palette.text.secondary }} fontSize='medium'>
                                        {Icons.restaurant}
                                    </IbssSvgIcon>
                                    <Typography variant='h5'>{this.props.title}</Typography>
                                </Box>
                            ) : (
                                <Typography variant='h5'>{this.props.title}</Typography>
                            )}
                        </Box>
                        <Box sx={{ display: 'flex', gap: "10px", justifyContent: "flex-end", alignItems: "center" }}>
                            {this.props.canExport && (
                                <IbssButtonRedo
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => this.props.exportClicked()}
                                    disabled={this.props.disableExport}
                                >
                                    {this.labels.HubButtonExport}
                                </IbssButtonRedo>
                            )}
                            {this.props.toggleButtonList && this.props.toggleButtonList?.length > 0 && (
                                <IbssToggleButton
                                    onChange={(event, newLayout) => this.props.onLayoutChange?.(newLayout)}
                                    exclusive
                                    size='small'
                                    value={this.props.layout}
                                    buttonList={this.props.toggleButtonList}
                                />
                            )}
                        </Box>
                    </Grid>
                    <Grid md={this.props.datesDisabled ?2:6} my={2}>
                    {!hasShowFilterFields && (
                        <Grid container mt={0} ml={0}>
                            <Grid md={!this.props.datesDisabled ? 3:12}>
                                <IbssFormControl fullWidth>
                                    <IbssInputDropDown
                                        id="demo-simple-select"
                                        value={this.props.selectedPreset}
                                        onChange={(e: SelectChangeEvent<string>) => this.props.presetChanged ? this.props.presetChanged(e) : null}
                                        options={[
                                            { label: this.labels.HubLabelToday, value: this.labels.HubLabelToday },
                                            ...(this.props.presets?.length > 0
                                                ? this.props.presets.map((label: string) => ({ label, value: label }))
                                                : []),
                                            { label: this.labels.HubLabelCustom, value: this.labels.HubLabelCustom }
                                        ]}
                                    />
                                </IbssFormControl>
                            </Grid>
                            <Grid md={9}>
                            {!this.props.datesDisabled && (
                                <Box>
                                    <Box  display={'flex'} sx={{'& > :not(style)': { px: 1}}}>
                                    <IbssDatePicker
                                        value={this.props.startDate ? this.props.startDate : DateTime.now()}
                                        onChange={event => event ? this.startDateChanged(event) : {}}
                                        label={this.labels.HubLabelFromDate}
                                        disabled={this.props.datesDisabled}
                                    />
                                    <IbssDatePicker
                                        value={this.props.endDate ? this.props.endDate : DateTime.now()}
                                        onChange={event => event ? this.endDateChanged(event) : {}}
                                        label={this.labels.HubLabelToDate}
                                        slotProps={{ textField: { error: toDateFieldError } }}
                                        disabled={this.props.datesDisabled}
                                        minDate={this.props.startDate ? this.props.startDate : undefined}
                                    />
                                    </Box>
                                    {this.props.endDate && this.props.startDate && this.props.endDate < this.props.startDate && (
                                        <Box className='text-danger' style={{ margin: 0 }}>
                                            {this.labels.HubLabelStartDateMustBeBeforeTheEndDate}
                                        </Box>
                                    )}
                                </Box>
                            )}
                            </Grid>
                        </Grid>
                    )}
                    </Grid>
                    <Grid md={6} my={2}>
                            <Grid container>
                                <Grid md={this.props.isOperationalServicesBookings ? 4 : 0} pl={2}>
                                {this.props.isOperationalServicesBookings && (
                                    <IbssFormControl variant="outlined" fullWidth>
                                        <IbssTextField
                                            type="text"
                                            size="medium"
                                            placeholder={this.labels.HubLabelSearch}
                                            value={this.props.searchTerm}
                                            onChange={(event) => this.props.filterTextChanged(event.target.value)}
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                        />
                                    </IbssFormControl>
                                )}
                                </Grid>
                                <Grid md={this.props.isOperationalServicesBookings ?4 : 0} pl={2}>
                                {this.props.isOperationalServicesBookings && (
                                    <IbssFormControl fullWidth size="small">
                                        <IbssInputDropDown
                                            inputLabel={this.labels.HubLabelPredefinedFilter}
                                            options={this.props.filterOptions}
                                            value={this.props.selectedFilterId}
                                            id={"filterSelection"}
                                            onChange={(e: SelectChangeEvent<string>) => this.props.filterChanged(e.target.value)}
                                        />
                                    </IbssFormControl>
                                )}
                                </Grid>
                                <Grid md={4} pl={2} alignItems={'center'}>
                                {this.props.isPrimariesBookings ? (
                                        <IbssInputDropDown
                                            options={this.props.primariesList}
                                            value={this.props.selectedPrimary}
                                            id={"ownerSelection"}
                                            isMultiple={false}
                                            onChange={(e: SelectChangeEvent<string>) => this.props.primaryChanged(e.target.value)}
                                            fullWidth
                                        />
                                ) : (
                                    <IbssButtonRedo
                                    sx={{mt:1}}
                                        variant="contained"
                                        onClick={() => this.props.onfilterButtonClicked()}
                                    >
                                        {this.props.isOperationalServicesBookings ? this.labels.HubTabCustomFilter : this.labels.HubLabelFilter}
                                    </IbssButtonRedo>
                                )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {this.props.description !== '' && (
                        <Grid item lg={12} sx={{ pt: 0 }}>
                            <Box className="table-panel-description" component="div" sx={{ ml: '0', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', width: "100%" }}>
                                {this.props.description}
                            </Box>
                        </Grid>
                    )}
            </>
        )
    }
};

interface IProps
{
    title: string;
    description?: string;
    presets: string[],
    selectedPreset?: string;
    presetChanged?: (e: SelectChangeEvent<string>) => void;
    startDate?: DateTime;
    onStartDateChange?: (e: DateTime) => void;    
    endDate?: DateTime;
    onEndDateChange?: (e: DateTime) => void;
    datesDisabled?: boolean,
    layout?: string;
    onLayoutChange?: (newLayout: string | null) => void;
    toggleButtonList?: toggleButtonListObject[];

    isOperationalServicesBookings: boolean;
    isPrimariesBookings: boolean;
    onfilterButtonClicked: () => void; 
    primaryChanged: (e:string) => void;
    selectedPrimary: string;
    primariesList: Array<IListOption<string>>;
    selectedFilterId: string;
    filterOptions: Array<IListOption<string>>;
    filterChanged: (e:string)=> void;
    searchTerm: string;
    filterTextChanged: (e:string)=> void;
    disableExport: boolean;
    canExport: boolean;
    exportClicked:() => void; 
}

interface IState {}

export default IbssBookingListHeader;


export interface IListOption<TValue>
{
    label: string,
    value: TValue,
}