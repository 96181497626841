import { UnregisterCallback } from "history";
import { IbssComponent } from "../BaseComponent/IbssComponent";
import { match, matchPath, RouteComponentProps } from "react-router";
import { appContext } from "../../../AppContext";
import { BuildingChangeReason } from "./Types";
import { BaseQueryParams } from "./BaseQueryParams";
import { IRoute, routes } from "../../Navigation/Router/IbssRouter";

export class IbssPage<TProps, TState, TQueryParams extends BaseQueryParams = BaseQueryParams> extends IbssComponent<TProps, TState>
{
    private get _appState() { return appContext().state; }
    private unregisterHistoryChanged: (UnregisterCallback | null) = null;
    private unregisterHistoryChanged2: (UnregisterCallback | null) = null;

    public currentRoute: IRoute | null = null;
    public queryParams: TQueryParams;

    constructor(props: TProps, queryParams?: TQueryParams)
    {
        super(props);
        this.queryParams = queryParams ?? new BaseQueryParams() as TQueryParams;

        let derivedComponentDidMount = this.componentDidMount;
        this.componentDidMount = () =>
        {
            // pre componentDidMount
            this.currentRoute = this.getRouteFromPath();

            // call derived componentDidMount
            const result: undefined | void | Promise<void> = derivedComponentDidMount?.bind(this)();
            const resultIsPromise = (result && typeof result == 'object' && typeof result['then'] == 'function');
            const resultAsPromise = (resultIsPromise ? result as Promise<void> : Promise.resolve());

            resultAsPromise.then(() =>
            {
                // post componentDidMount
                if (queryParams)
                {
                    this.subscribeToQueryParamChanges();
                }
            });
        };
    }

    public getRouteFromPath(): IRoute | null
    {
        const props = this.props as unknown as RouteComponentProps;
        if (!('history' in props))
        {
            return null;
        }
        for (const route of routes)
        {
            const match = matchPath(props.history.location.pathname, { path: route.path, exact: true, strict: false });
            if (match)
            {
                return route;
            }
        }
        return null;
    }

    public get pageTitle(): string
    {
        return this._appState.pageTitle;
    }

    public set pageTitle(value: string)
    {
        this._appState.set({ pageTitle: value });
    }

    public get area(): '' | 'admin' | 'onelens' | 'flex'
    {
        const path = window.location.pathname;
        if (path.startsWith('/admin') || path.startsWith('/security-') || path.startsWith('/portfolio-setup-') || path.startsWith('/filemgmt/'))
        {
            return 'admin';
        }
        else if (path.startsWith('/one-lens') || path.startsWith('/operational-') || path.startsWith('/sustainability-analytics-') || path.startsWith('/space-analytics-'))
        {
            return 'onelens';
        }
        else if (path.startsWith('/flex/') || path.startsWith('/flex-'))
        {
            return 'flex';
        }
        else
        {
            return '';
        }
    }

    // todo:
    // need to be careful derived class calls this otherwise listeners won't unregister
    // would be better to wire up in the constructor so no needs to manually call base method
    public componentWillUnmount(): void
    {
        this.pageTitle = "";
        this.unregisterHistoryChanged?.();
        this.unregisterHistoryChanged2?.();
    }

    /** @deprecated Use 'queryParams' instead. Search for a page that uses this as an example (e.g. ListVisits). */
    public onBuildingIdChanged<TMatchParams>(getBuildingIdMatchParam: (matchParams: TMatchParams) => string, buildingIdChanged: (buildingId: number, changeReason: BuildingChangeReason) => Promise<void>): void
    {
        const props = this.props as unknown as RouteComponentProps;

        const getBuildingIdMatch = (): (number | null) =>
        {
            const match = matchPath(window.location.pathname, { path: props.match.path });
            if (match == null)
            {
                return null;
            }
            const buildingId = parseInt(getBuildingIdMatchParam(match.params as TMatchParams));
            return (isNaN(buildingId) ? null : buildingId);
        }

        // handle building selector change
        const ref = this._appState.subscribe(this, async i =>
        {
            const buildingIdMatch = getBuildingIdMatch();
            if (i.buildingId == null || i.buildingId == buildingIdMatch)
            {
                return;
            }
            await buildingIdChanged(i.buildingId, "BuildingSelectorChanged");
        }, false);

        // update building drop-down when URL changed, then invoke event handler
        if (props.history != null)
        {
            this.unregisterHistoryChanged = props.history.listen(async () =>
            {
                const buildingIdMatch = getBuildingIdMatch();
                if (buildingIdMatch == null || this._appState.buildingId == buildingIdMatch)
                {
                    return;
                }

                await this._appState.set({ buildingId: buildingIdMatch });
                await buildingIdChanged(buildingIdMatch, "UrlChanged");
            });
        }
    }

    private subscribeToQueryParamChanges(): void
    {
        if (!('history' in this.props))
        {
            throw new Error("'props.history' must be defined when listening to query parameter changes.");
        }

        const queryParams = this.queryParams;
        const props = this.props as unknown as RouteComponentProps;
        this.unregisterHistoryChanged2 = props.history.listen(async () =>
        {
            this.handleQueryParamChange();
        });

        this._appState.subscribe(
            this,
            async appState =>
            {
                if (!appState.buildingId)
                {
                    return;
                }
                if (appState.buildingId == queryParams.building)
                {
                    return;
                }
                queryParams.building = appState.buildingId;
                await this.buildingQueryParamDidChange();
            },
            false);

        this.handleQueryParamChange();
    }

    public async buildingQueryParamDidChange(): Promise<void>
    {
        this.pushQueryParams();
    }

    public pushQueryParams(): void
    {
        if (!('history' in this.props))
        {
            throw new Error("'props.history' must be defined pushing query parameters.");
        }

        const props = this.props as unknown as RouteComponentProps;
        const url = window.location.href;
        const urlObject = new URL(url);
        const queryString = this.queryParams.toQueryString();
        const newUrl = `${urlObject.pathname}?${queryString}`;
        props.history.push(newUrl);
    }

    private handleQueryParamChange(): void
    {
        const props = this.props as unknown as RouteComponentProps;
        if (this.currentRoute)
        {
            const match = matchPath(props.history.location.pathname, { path: this.currentRoute.path, exact: true, strict: false });
            if (!match) // route is about to change, no point in calling queryParamsDidUpdate
            {
                return;
            }
        }

        const queryParams = this.queryParams;
        const prevQueryParams = queryParams.copy() as TQueryParams;
        queryParams.parseQueryString();

        if (this.area != 'flex' && queryParams.building && queryParams.building > 1 && this._appState.buildingId != queryParams.building)
        {
            appContext().state.set({ buildingId: queryParams.building });
        }
        this.queryParamsDidUpdate(prevQueryParams);
    }

    public async queryParamsDidUpdate(prevParams?: TQueryParams): Promise<void>
    {
    }
}
