import React, { useState, useEffect, Component, ReactNode } from "react";
import { BrowserRouter as Router, Switch, Redirect, Route, RouteComponentProps, useHistory } from "react-router-dom";
import LoadingOverlay from "../LoadingOverlay/LoadingOverlay";
import { oktaAuthConfig } from "../../../configOkta";
import { Security } from "@okta/okta-react";
import { toRelativeUrl } from "@okta/okta-auth-js";
import apis from "../../../Providers.Api/apis";
import Layout from "../../Layout/Layout/Layout";
import { appContext } from "../../../AppContext";
import { LogOutReason } from "../../../Providers.Api/Authentication";
import { MenuItemKey } from "../../Layout/Sidebar/MenuItemKey";
import { ConnectedComponent } from "react-redux";
import { StaticContext } from "react-router";
import NotFound from "../../../Pages/Public/NotFound";
import PublicRoutes from './PublicRoutes';
import SharedRoutes from "./SharedRoutes";
import AdminRoutes from "./AdminRoutes";
import OneLensRoutes from "./OneLensRoutes";
import FlexRoutes from "./FlexRoutes";
import { IParameter } from "../../../Providers.Api/Parameters/ParameterRepository";

export let routes: IRoute[] = [];

export default class Routes extends Component<IProps, IState>
{
    private get auth() { return appContext().authentication; }
    private get local() { return appContext().localStorageProvider; }
    private get appState() { return appContext().state; }

    private publicRoutes = new PublicRoutes();
    private sharedRoutes = new SharedRoutes();
    private adminRoutes = new AdminRoutes();
    private oneLensRoutes = new OneLensRoutes();
    private flexRoutes = new FlexRoutes();

    constructor(props: IProps)
    {
        super(props);
        routes = [
            ...this.publicRoutes.routes,
            ...this.sharedRoutes.routes,
            ...this.adminRoutes.routes,
            ...this.oneLensRoutes.routes,
            ...this.flexRoutes.routes,
        ];
    }

    private restoreOriginalUri(originalUri: string): void
    {
        const history = (this.props as RouteComponentProps).history;
        if (history)
        {
            history.replace(toRelativeUrl(originalUri, window.location.origin + "/auth"));
        }
        else
        {
            window.location.href = window.location.origin + "/auth";
        }
    }

    // todo: nothing is calling this method. is there a bug where an old page title persists when changing route?
    private resetPageTitle(): void
    {
        this.appState.set({ pageTitle: "" });
    }

    private isAuthenticated(): boolean
    {
        let isAuthenticated = true;
        const token = this.local.getToken()
        if (!token)
        {
            isAuthenticated = false;
        }
        return isAuthenticated;
    }

    private isAuthorised(requiredRights: string[]): boolean
    {
        if (requiredRights.length == 0)
        {
            return true;
        }
        for (const right of requiredRights)
        {
            const hasRight = this.local.hasRight(right);
            if (hasRight)
            {
                return true;
            }
        }
        return false;
    }

    public render(): JSX.Element
    {
        const oktaAuth = oktaAuthConfig;
        return (
            <Security oktaAuth={oktaAuth} restoreOriginalUri={(_oktaAuth, originalUri) => this.restoreOriginalUri(originalUri)}>
                <Router>
                    <Switch>
                        {
                            routes.map(route =>
                            {
                                const components: JSX.Element[] = [];
                                if (route.legacyPaths)
                                {
                                    const redirects = route.legacyPaths.map(legacyPath => this.renderRedirect(route, legacyPath));
                                    components.push(...redirects);
                                }
                                if (route.component) // todo: is this check needed?
                                {
                                    const page = (route.isPublic ? this.renderPublicRoute(route) : this.renderPrivateRoute(route));
                                    components.push(page);
                                }
                                return components;
                            })
                        }
                        <Route exact path="*">
                            <NotFound />
                        </Route>
                    </Switch>
                </Router>
            </Security>
        );
    }

    private renderRedirect(route: IRoute, legacyPath: ILegacyRoute): JSX.Element
    {
        return (
            <Route
                key={legacyPath.path}
                path={legacyPath.path}
                exact={true}
                render={props => (<Redirect to={this.mapLagacyPathToNewPath(route, legacyPath, props)} />)}
            />
        );
    }

    private mapLagacyPathToNewPath(route: IRoute, legacyPath: ILegacyRoute, props: RouteComponentProps<{ [key: string]: string | undefined }>): string
    {
        let path = route.path;
        let query = '';

        for (const paramName in props.match.params)
        {
            const paramAlias = legacyPath.parameterMap?.[paramName] ?? paramName;
            const paramPlaceholder = ':' + paramAlias;
            const paramValue = props.match.params[paramName] ?? '';

            if (path.includes(paramPlaceholder))
            {
                path = path.replace(new RegExp(paramPlaceholder, 'g'), paramValue); //  assumption that paramAlias conatins no reg-ex chars
            }
            else
            {
                query = [query, `${paramAlias}=${paramValue}`].filter(i => !!i).join('&');
            }
        }

        const originalQuery = props.location.search.replace(/^\?/, '');
        const fullQuery = [query, originalQuery].filter(i => !!i).join('&');
        const pathAndQuery = [path, fullQuery].filter(i => !!i).join('?');
        return pathAndQuery;
    }

    private renderPublicRoute(route: IRoute): JSX.Element
    {
        return (
            <Route
                key={route.path}
                path={route.path}
                exact={true}
                render={(props) =>
                {
                    return (
                        <Layout {...props} isPrivate={false}>
                            <route.component {...props} />
                        </Layout>
                    );
                }}
            />
        );
    }

    private renderPrivateRoute(route: IRoute): JSX.Element
    {
        return (
            <Route
                key={route.path}
                path={route.path}
                exact={true}
                render={props =>
                {
                    const isAuthenticated = this.isAuthenticated();
                    const isAuthorised = this.isAuthorised(route.requiredRights);

                    if (!isAuthenticated)
                    {
                        this.auth.logOut(LogOutReason.SessionExpired);
                        return <></>;
                    }
                    else if (!isAuthorised)
                    {
                        return <Redirect to={{ pathname: "/norights" }} />;
                    }
                    else
                    {
                        return (
                            <Layout {...props} isPrivate={true}>
                                <route.component {...props} />
                            </Layout>
                        );
                    }
                }}
            />
        );
    }
}

export interface IProps { }
export interface IState { }

export interface IRoute
{
    isPublic?: boolean;
    requiredRights: string[];
    path: string;
    legacyPaths?: ILegacyRoute[];
    component: React.ComponentType<any>;
    menuItemId?: MenuItemKey;
}

export interface ILegacyRoute
{
    path: string;
    parameterMap?: IRouteParameterMap;
}

export interface IRouteParameterMap
{
    [oldName: string]: string;
}
