import { Component } from "react";
import "../../../../styles/css/searchspace.scss";
import "../../../../App.css";
import 'react-modern-drawer/dist/index.css';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Helper from "../../../../Common/Helper";
import { appContext } from "../../../../AppContext";
import IbssSwitchLabel, { LabelPlacementType } from "../../../../Components/Inputs/Switch/IbssSwitchLabel";
import IbssTextField from "../../../../Components/Inputs/TextField/IbssTextField";
import { Box } from "@mui/system";
import { Typography, Paper } from '@mui/material'
import IbssInputLabel from '../../../../Components/Forms/InputLabel/IbssInputLabel';
import Grid from '@mui/material/Grid';
import IbssDateTimePicker from "../../../../Components/Inputs/DateTimePicker/IbssDateTimePicker";
import TextField from '@mui/material/TextField';
import { INode, IFloor, IRootNode, ISearchConfigItem, IZone } from "../../../../Providers.Api/Models";
import { DateTime, MinuteNumbers, WeekdayNumbers } from "luxon";
import { IPropsFromState } from "../../../../redux/Interfaces";
import { IBuildingConfig } from "../../../../Common/ConfigHelper";
import { DateHelper } from "../../../../Common/DateHelper";
import { IUserPreferences } from "../../../../Providers.Api/UserPreferences/UserPreferenceRepository";
import IbssToolTip from "../../../../Components/Miscellaneous/Tooltip/IbssToolTip";
import InfoIcon from "../../../../Components/Icons/InfoIcon";
import IbssDatePicker from "../../../../Components/Inputs/DatePicker/IbssDatePicker";
import IbssTimePicker from "../../../../Components/Inputs/TimePicker/IbssTimePicker";
import IbssDrawer from "../../../../Components/Drawer/IbssDrawer";

export default class SearchCriteria extends Component<IProps, IState>
{
    private todaysNewDate = DateHelper.fromIsoToJsDate(DateHelper.now().toString());
    private apiCache = appContext().apiCache;
    private localStorage = appContext().localStorageProvider;
    private alert = appContext().alert;
    private labels = appContext().labels;
    private history = appContext().router.history;
    private config = appContext().config;
    private userPreferences = {} as IUserPreferences;
    private buildingConfig = {} as IBuildingConfig;
    private get allowedMinutes(): MinuteNumbers[] { return this.buildingConfig.allowedMinutes; }
    private get apiClient() { return appContext().apiClient; }

    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
            buildingOptions: [],
            workSpaceTypeOptions: [],
            spaceTypeOptions: [],
            floorTypeOptions: [],
            zoneOptions: [],

            // search criteria
            selectedBuildingOption: props.buildingOptions,
            selectedWorkSpaceType: props.selectedWorkspaceTypes ?? 'Any',
            selectedSpaceTypes: props.selectedSpaceTypes?? 'Any',
            selectedFloor: props.selectedFloor?? 'Any',
            selectedZone: props.selectedZone?? 'Any',
            startTime: props.startTime,
            startTimeMessage: "",
            endTime: props.End_Date_For_filter_modal,
            endTimeMessage: "",
            date: DateTime.fromJSDate(props.startTime),
            minTime: this.todaysNewDate,
            audioVisual: props.av,
            presentationAids: props.presentationAids,
            hearingAids: props.hearingAids,
            catering: props.catering,
            linkedSpace: props.linkedSpace,
            layouts: props.layouts,
            numberOfPeople: props.numberOfPeople,
            userBypassingPolicies: true
        };
    }

    public async componentDidMount(): Promise<void>
    {
        // load config
        this.todaysNewDate = DateHelper.now(this.state.selectedBuildingOption);
        this.userPreferences = await this.localStorage.getUserPreferences();
        let rootNode = this.localStorage.getNodeData();
        this.buildingConfig = this.config.getBuildingConfig(rootNode, this.state.selectedBuildingOption);

        // enforce user prefs
        if (this.userPreferences.SearchPrefs.DefaultBuilding == null)
        {
            this.alert.show(this.labels.HubLabelSetyourpreferences, this.labels.HubLabelSetUserPrefenceError, () => this.history.push(`/flex-user-pref-workplace`));
            return;
        }

        // load options
        this.populateBuildings(this.state.selectedBuildingOption);
        this.populateWorkSpaces(this.state.selectedBuildingOption, this.state.selectedWorkSpaceType);
        this.populateSpaceTypes(this.state.selectedBuildingOption, this.state.selectedSpaceTypes);
        this.populateFloors(this.state.selectedBuildingOption, this.state.selectedFloor);
        this.populateZones(this.state.selectedFloor, this.state.selectedZone);

        this.setState({
            userBypassingPolicies: (this.localStorage.hasRight("API.Bookings.BookOutsideWorkingHours") && this.localStorage.permissionAppliesToBuilding('API.Bookings.BookOutsideWorkingHours', this.state.selectedBuildingOption)) ||
                (this.localStorage.hasRight("API.Bookings.BookOutsideOfficeHours") && this.localStorage.permissionAppliesToBuilding('API.Bookings.BookOutsideOfficeHours', this.state.selectedBuildingOption)) ||
                (this.localStorage.hasRight("API.Bookings.IgnoreTimeHorizon") && this.localStorage.permissionAppliesToBuilding('API.Bookings.IgnoreTimeHorizon', this.state.selectedBuildingOption)) ||
                (this.localStorage.hasRight("API.Bookings.OverrideSetupTeardown") && this.localStorage.permissionAppliesToBuilding('API.Bookings.OverrideSetupTeardown', this.state.selectedBuildingOption)) ||
                (this.localStorage.hasRight("API.Bookings.BookOutsidePolicySlots") && this.localStorage.permissionAppliesToBuilding('API.Bookings.BookOutsidePolicySlots', this.state.selectedBuildingOption))
        });
    }

    private populateBuildings(selectedValue: number): void
    {
        const buildings: INode[] = Helper.getAllBuildingsData();
        const options = buildings.map(i => ({ label: i.Name, value: i.Node_Id }));
        this.setState({ buildingOptions: options, selectedBuildingOption: selectedValue });
    }

    private populateWorkSpaces(selectedBuildingId: number, selectedWorkSpace: string): void
    {
        const workSpaces: ISearchConfigItem[] = Helper.getWorkSpaceTypesByNodeId(selectedBuildingId);

        const options = workSpaces
            .filter(i => i.Name != null)
            .map(i => ({ label: i.Label, value: i.Name }));

        options.unshift({ label: "Any", value: "Any" });
        this.setState({ workSpaceTypeOptions: options, selectedWorkSpaceType: selectedWorkSpace });
    }

    private populateSpaceTypes(selectedBuildingId: number, selectedSpaceType: string): void
    {
        const spaceTypes = Helper.getSpaceTypesByNodeId(selectedBuildingId);

        const options = spaceTypes.result
            .filter(i => i.Name != null)
            .map(i => ({ label: i.Label, value: i.Name }))
            .sort((a, b) => a.label.localeCompare(b.label)); 
            
        options.unshift({ label: "Any", value: "Any" });
        this.setState({ spaceTypeOptions: options, selectedSpaceTypes: selectedSpaceType });
    }

    private populateFloors(selectedBuildingId: number, selectedFloor: string): void
    {
        const floors: IFloor[] = Helper.getFloorsByBuildingId(selectedBuildingId);

        const options = floors
            .map(i => ({ label: i.Node_Name, value: i.Node_Id }))
            .sort((a, b) => (a.label < b.label ? - 1 : 1)); // sort by name

        this.setState({ floorTypeOptions: options, selectedFloor: selectedFloor });
    }

    private async populateZones(selectedFloor: string, selectedZone: string): Promise<void>
    {
        if(!this.props.isSearchSpace)
        {
            if (selectedFloor === "Any")
            {
                this.setState({ zoneOptions: [{ label: "*** Select a Floor ***", value: "Any" }], selectedZone: selectedZone });
                return;
            }
    
            const zonesResponse = await this.apiClient.spaceZones.getMultiple(parseInt(selectedFloor), true);
            const zones = zonesResponse;
            const options = zones.map(i => ({ label: i.Meta_Loc_Zone, value: i.Meta_Loc_Zone }));
            options.unshift({ label: "Any", value: "Any" });
            this.setState({ zoneOptions: options, selectedZone: selectedZone });
        }
    }

    private async buildingChanged(event: SelectChangeEvent<number>): Promise<void> 
    {
        const selectedBuildingId = event.target.value as number;
        const spaceTypes = Helper.getSpaceTypesByNodeId(selectedBuildingId);
        const selectedSpace  = spaceTypes.result[0]?.Name;

        const selectedFloor = this.userPreferences.Nodes.find(building => building.NodeId === selectedBuildingId)?.DefaultFloor?.toString() ?? "Any";
        this.populateWorkSpaces(selectedBuildingId, "Any");
        this.populateSpaceTypes(selectedBuildingId, selectedSpace);
        this.populateFloors(selectedBuildingId, selectedFloor);
        this.populateZones(selectedFloor, "Any");

        const defaultTimes = this.config.getDefaultTimes(this.buildingConfig, this.userPreferences.WorkingHoursPrefs, false, true);
        this.todaysNewDate = DateHelper.now(selectedBuildingId);
        this.setState({
            selectedBuildingOption: selectedBuildingId,
            startTime: defaultTimes.start.toJSDate(),
            endTime: defaultTimes.end.toJSDate(),
            userBypassingPolicies: (this.localStorage.hasRight("API.Bookings.BookOutsideWorkingHours") && this.localStorage.permissionAppliesToBuilding('API.Bookings.BookOutsideWorkingHours', selectedBuildingId)) ||
            (this.localStorage.hasRight("API.Bookings.BookOutsideOfficeHours") && this.localStorage.permissionAppliesToBuilding('API.Bookings.BookOutsideOfficeHours', selectedBuildingId)) ||
            (this.localStorage.hasRight("API.Bookings.IgnoreTimeHorizon") && this.localStorage.permissionAppliesToBuilding('API.Bookings.IgnoreTimeHorizon', selectedBuildingId)) ||
            (this.localStorage.hasRight("API.Bookings.OverrideSetupTeardown") && this.localStorage.permissionAppliesToBuilding('API.Bookings.OverrideSetupTeardown', selectedBuildingId)) ||
            (this.localStorage.hasRight("API.Bookings.BookOutsidePolicySlots") && this.localStorage.permissionAppliesToBuilding('API.Bookings.BookOutsidePolicySlots', selectedBuildingId))
        });
    }

    private workSpaceChanged(event: SelectChangeEvent): void
    {
        let spaceType = (event.target.value == "Any" ? this.state.spaceTypeOptions[1]?.value ?? "Any" : "Any");
        this.setState({ selectedWorkSpaceType: event.target.value, selectedSpaceTypes: spaceType });
    }

    private spaceTypeChanged(event: SelectChangeEvent): void
    {
        let workSpaceType = (event.target.value == "Any" ? this.state.workSpaceTypeOptions[1]?.value ?? "Any" : "Any");
        this.setState({ selectedSpaceTypes: event.target.value, selectedWorkSpaceType: workSpaceType });
    }

    private async floorChanged(event: SelectChangeEvent): Promise<void>
    {
        await this.setState({ selectedFloor: event.target.value });
        this.populateZones(event.target.value, "Any");
    }

    private zoneChanged(event: SelectChangeEvent): void
    {
        this.setState({ selectedZone: event.target.value });
    }

    private dateChanged(date: DateTime | null): void
    {
        if(date != null)
        {
            this.setState({
                date: date,
                startTime: DateTime.fromJSDate(this.state.startTime).set({ year: date.year, month: date.month, day: date.day }).toJSDate(),
                endTime: DateTime.fromJSDate(this.state.endTime).set({ year: date.year, month: date.month, day: date.day }).toJSDate()
            });
        }
    }

    private startTimeChanged(value: DateTime | null): void
    {
        const startTime = (value ?? DateHelper.null());
        const endTime = DateTime.fromJSDate(this.state.endTime);
        const validator = new Validator(this.allowedMinutes);
        validator.validate(startTime, endTime, this.state.selectedBuildingOption);
        this.setState({ startTime: startTime.toJSDate(), startTimeMessage: validator.toStartTimeMessage(), endTimeMessage: validator.toEndTimeMessage() });
    }

    private endTimeChanged(value: DateTime | null): void
    {
        const startTime = DateTime.fromJSDate(this.state.startTime);
        const endTime = (value ?? DateHelper.null());
        const validator = new Validator(this.allowedMinutes);
        validator.validate(startTime, endTime, this.state.selectedBuildingOption);
        this.setState({ endTime: endTime.toJSDate(), startTimeMessage: validator.toStartTimeMessage(), endTimeMessage: validator.toEndTimeMessage() });
    }

    private audioVisualChanged(e: React.ChangeEvent<HTMLInputElement>): void
    {
        this.setState({ audioVisual: e.target.checked });
    }

    private presentationAidsChanged(e: React.ChangeEvent<HTMLInputElement>): void
    {
        this.setState({ presentationAids: e.target.checked });
    }

    private hearingAidsChanged(e: React.ChangeEvent<HTMLInputElement>): void
    {
        this.setState({ hearingAids: e.target.checked });
    }

    private cateringChanged(e: React.ChangeEvent<HTMLInputElement>): void
    {
        this.setState({ catering: e.target.checked });
    }

    private linkedSpaceChanged(e: React.ChangeEvent<HTMLInputElement>): void
    {
        this.setState({ linkedSpace: e.target.checked });
        if (e.target.checked)
        {
            this.setState({ layouts: true });
        }
    }

    private layoutsChanged(e: React.ChangeEvent<HTMLInputElement>): void
    {
        this.setState({ layouts: e.target.checked });
    }

    private numberOfPeopleChanged(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void
    {
        const input = e.target.value;
        const expression = /^[0-9\b]+$/ // only accept digits
        if (input === "" || expression.test(input))
        {
            this.setState({ numberOfPeople: e.target.value });
        }
    }

    private async clearClicked(): Promise<void>
    {
        const userPreferences = this.userPreferences;

        let rootNode = this.localStorage.getNodeData();
        let buildingConfig = this.config.getBuildingConfig(rootNode, this.state.selectedBuildingOption);
        let defaultTimes = this.config.getDefaultTimes(buildingConfig, userPreferences.WorkingHoursPrefs, false, true);
        const startDateAndTime = defaultTimes.start.toJSDate();
        const endDateAndTime = defaultTimes.end.toJSDate();

        this.setState(
        {
            selectedWorkSpaceType: 'Any',
            selectedSpaceTypes: this.props.selectedSpaceTypes?? 'Any',
            selectedFloor: 'Any',
            selectedZone: 'Any',
            startTime: startDateAndTime,
            endTime: endDateAndTime,
            audioVisual: false,
            presentationAids: false,
            hearingAids: false,
            catering: false,
            linkedSpace: false,
            layouts: false,
            numberOfPeople: '',
        });
    }

    private async updateClicked(): Promise<void>
    {
        // validate times
        const startTime = DateTime.fromJSDate(this.state.startTime);
        const endTime = DateTime.fromJSDate(this.state.endTime);
        const validator = new Validator(this.allowedMinutes);
        validator.validate(startTime, endTime, this.state.selectedBuildingOption);

        if (!validator.isValid())
        {
            this.alert.show(this.labels.HubLabelError, validator.toPopupMessage(), undefined, { messageIsHtml: true });
            return;
        }

        // give result to callback
        const numberOfPeople: (number | null) = parseInt(this.state.numberOfPeople);
        const handleAny = (value: string): (string | null) => (value == null || value == "" || value == "Any" ? null : value);
        const floorId = handleAny(this.state.selectedFloor);
        const spaceTypeLabel = (this.state.spaceTypeOptions.find(i => i.value == this.state.selectedSpaceTypes)?.label ?? "");
       
        let searchCriteria: ISearchCriteriaResult =
        {
            buildingId: this.state.selectedBuildingOption,
            workspaceType: handleAny(this.state.selectedWorkSpaceType),
            spaceType: handleAny(this.state.selectedSpaceTypes),
            spaceTypeLabel: handleAny(spaceTypeLabel),
            floorId: (floorId == null ? null : parseInt(floorId)),
            zone: handleAny(this.state.selectedZone),
            startTime: startTime,
            endTime: endTime,
            audioVisual: this.state.audioVisual,
            presentationAids: this.state.presentationAids,
            hearingAids: this.state.hearingAids,
            catering: this.state.catering,
            linkedSpace: this.state.linkedSpace,
            layouts: this.state.layouts,
            numberOfPeople: (isNaN(numberOfPeople) ? null : numberOfPeople),
        };

        await this.props.updateSearchResults(searchCriteria);
        this.props.closeClicked();
    }

    public render()
    {
        const { open, closeClicked } = this.props;
        // labelStyle for styling MUI labels components via sx props, based on the css class .flexMySearch-filter-criteria-select-label, which cannot be directly applied to Typography, because default Typography have more properties to be overriden than is specified in css class.
        const labelStyles = { fontFamily: 'Source Sans Pro', fontStyle: 'normal', fontSize: '14px', fontWeight: 700, lineHeight: '18px', paddingLeft: '3px', letterSpacing: '0em' }
        const disableUpdate = this.state.startTimeMessage.length > 0 || this.state.endTimeMessage.length > 0;

        return (
            <>
                <IbssDrawer open={open} onClose={() => closeClicked()} anchor='right' className='flex-search-filter-criteria' style={{ backgroundColor: "var(--ui-background-alternate)" }}>
                    <div className="flexMySearch-filter-criteria">
                        <div className="flexMySearch-filter-criteria-header">
                            <span className="flexMySearch-filter-criteria-icon"><img className="flexMySearch-filter-criteria-img " src={`/images/Sidebar_Icons/${this.props.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Filter-2.svg`} alt="icon" /></span>
                            <span className="flexMySearch-filter-criteria-title">{!this.props.isSearchSpace ? this.labels.HubLabelflexFilterSearchCritera : this.labels.funcAdditionalSearchCriteria_S} </span>
                            <span className="flexMySearch-filter-criteria-close" onClick={() => closeClicked()}>&times;</span>
                        </div>


                        <div className="flexMySearch-filter-criteria-content">
                            {!this.props.isSearchSpace &&
                                <>
                                    <div className="flexMySearch-filter-criteria-firstLabel pt-0">{this.labels.HubLabelBuilding}</div>
                                    <fieldset>
                                        <div className="row flexMySearch-filter-criteria-selectBldng-filter">
                                            <Typography className="col-5 card ml-0 flexMySearch-filter-criteria-select-label">{this.labels.HubLabelSelectBuilding}</Typography>
                                            <div className="col-7 d-flex justify-content-end card mr-0 flexMySearch-filter-criteria-select-selectBox">
                                                <FormControl fullWidth size="small">
                                                    <InputLabel shrink={false} className="fms-fc-placeholder">
                                                        {this.state.selectedBuildingOption == -1 && '***Select an Option***'}
                                                    </InputLabel>

                                                    <Select
                                                        displayEmpty
                                                        value={this.state.selectedBuildingOption}

                                                        onChange={e => this.buildingChanged(e)}
                                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}>

                                                        {this.state.buildingOptions.sort((a,b) => a.label > b.label ? 1 : -1).map(eachVal =>
                                                            <MenuItem value={eachVal.value}>{eachVal.label}</MenuItem>
                                                        )}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </div>
                                    </fieldset>
                                    <div className="flexMySearch-filter-criteria-border" />

                                    {/* Workspaces */}
                                    <fieldset>
                                        <Typography variant="body2" className="flexMySearch-filter-criteria-firstLabel">
                                            {this.labels.HubLabelworkspaces}
                                        </Typography>
                                        <div className="row flexMySearch-filter-criteria-selectBldng-filter">
                                            <Typography variant="body2" className="col-5 card ml-0 flexMySearch-filter-criteria-select-label">
                                                {this.labels.HubLabelworkType}
                                            </Typography>
                                            <div className="col-7 d-flex justify-content-end card mr-0 flexMySearch-filter-criteria-select-selectBox">
                                                <FormControl fullWidth size="small">
                                                    <InputLabel shrink={false} className="fms-fc-placeholder">
                                                        {this.state.selectedWorkSpaceType == '' && '***Select an Option***'}
                                                    </InputLabel>
                                                    <Select
                                                        labelId="fms_fc_ws-label"
                                                        id="fms_fc_bldng"
                                                        value={this.state.selectedWorkSpaceType}
                                                        onChange={e => this.workSpaceChanged(e)}
                                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}>
                                                        {this.state.workSpaceTypeOptions.map(eachVal => 
                                                        {
                                                            return (
                                                                <MenuItem value={eachVal.value}>{eachVal.label}</MenuItem>
                                                            )
                                                        })}
                                                    </Select>
                                                </FormControl>

                                            </div>
                                        </div>
                                        <div className="flexMySearch-filter-criteria-border" />

                                        {/* spacesType */}
                                        <div className="row flexMySearch-filter-criteria-selectBldng-filter">

                                            <Typography variant="body2" className="col-5 card   ml-0 flexMySearch-filter-criteria-select-label">{this.labels.HubLabelBySpaceType}</Typography>
                                            <div className="col-7 d-flex justify-content-end card mr-0 flexMySearch-filter-criteria-select-selectBox">
                                                <FormControl fullWidth size="small">
                                                    <InputLabel shrink={false} className="fms-fc-placeholder">
                                                        {this.state.selectedSpaceTypes == '' && '***Select an Option***'}
                                                    </InputLabel>
                                                    <Select
                                                        value={this.state.selectedSpaceTypes}
                                                        onChange={e => this.spaceTypeChanged(e)}
                                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}>
                                                        {this.state.spaceTypeOptions.map(eachVal => 
                                                        {
                                                            return (
                                                                <MenuItem value={eachVal.value}>{eachVal.label}</MenuItem>
                                                            )
                                                        })}
                                                    </Select>
                                                </FormControl>

                                            </div>
                                        </div>
                                        <div className="flexMySearch-filter-criteria-border" />
                                    </fieldset>
                                    {/* Floor */}
                                    <fieldset>
                                        <Typography variant="body2" className="flexMySearch-filter-criteria-firstLabel">
                                            {this.labels.HubLabelLocation}
                                        </Typography>
                                        <div className="row flexMySearch-filter-criteria-selectBldng-filter">
                                            <div className="col-5 card   ml-0 flexMySearch-filter-criteria-select-label">{this.labels.HubLabelFloors}</div>
                                            <div className="col-7 d-flex justify-content-end card mr-0 flexMySearch-filter-criteria-select-selectBox">
                                                <FormControl fullWidth size="small">
                                                    <InputLabel shrink={false} className="fms-fc-placeholder ">
                                                        {this.state.selectedFloor == '' && '***Select an Option***'}
                                                    </InputLabel>
                                                    <Select
                                                        // multiple
                                                        //  (if you uncomment the multiple we can use this one in future for multiple floor)

                                                        value={this.state.selectedFloor}
                                                        onChange={e => this.floorChanged(e)}
                                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}>
                                                        <MenuItem value="Any">Any</MenuItem>
                                                        {this.state.floorTypeOptions.map(eachVal => 
                                                        {
                                                            return (
                                                                <MenuItem value={eachVal.value}>{eachVal.label}</MenuItem>
                                                            )
                                                        })}
                                                    </Select>

                                                </FormControl>

                                            </div>
                                        </div>
                                        <div className="flexMySearch-filter-criteria-border" />

                                        {/* Zones */}
                                        <div className="row flexMySearch-filter-criteria-selectBldng-filter">
                                            <Typography variant="body2" className="col-5 card pt-0 ml-0 flexMySearch-filter-criteria-select-label">{this.labels.HubLabelZone}</Typography>
                                            <div className="col-7 card pt-0 d-flex justify-content-end mr-0 flexMySearch-filter-criteria-select-selectBox">
                                                <FormControl fullWidth size="small">
                                                    <InputLabel shrink={false} className="fms-fc-placeholder">
                                                        {this.state.selectedZone == '' && '***Select an Option***'}
                                                    </InputLabel>
                                                    <Select
                                                        disabled={this.state.selectedFloor === "Any"}
                                                        value={this.state.selectedZone}
                                                        onChange={e => this.zoneChanged(e)}
                                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}>
                                                        {this.state.zoneOptions.map(eachVal => 
                                                        {
                                                            return (
                                                                <MenuItem value={eachVal.value}>{eachVal.label}</MenuItem>
                                                            )
                                                        })}
                                                    </Select>

                                                </FormControl>

                                            </div>
                                        </div>
                                    </fieldset>
                                </>
                            }                        
                            <fieldset>
                                <div className="flexMySearch-filter-criteria-border" />
                                {/* date and time */}
                                <Typography variant="body2" className="flexMySearch-filter-criteria-firstLabel" style={{ width: '550px', display: 'block' }}>
                                    {this.labels.HubLabelDateandTime} { this.state.userBypassingPolicies && <><mark style={{ background: 'unset' }}>-</mark><mark style={{ color: 'red', background: 'unset' }}> {this.labels.funcBypassingPolicies_Message}</mark></>}
                                </Typography>
                                <div className="row flexMySearch-filter-criteria-selectBldng-filter">
                                    <Typography variant="body2" className="col-5 card ml-0 flexMySearch-filter-criteria-select-label">{this.labels.HubLabelDate}</Typography>
                                    <div className="col-7 d-flex justify-content-end card mr-0 flexMySearch-filter-criteria-select-selectBox">
                                        <FormControl fullWidth size="small">
                                            <IbssDatePicker
                                                value={this.state.date}
                                                onChange={event => this.dateChanged(event)}
                                                minDate={DateHelper.now()}
                                                slotProps={{ textField: { size: 'small' } }}
                                            />
                                        </FormControl>
                                    </div>
                                </div>
                                <div className="flexMySearch-filter-criteria-border" />
                                <div className="row flexMySearch-filter-criteria-selectBldng-filter">
                                    <Typography variant="body2" className="col-5 card ml-0 pt-0 flexMySearch-filter-criteria-select-label">{this.labels.HubLabelSelectStart}</Typography>
                                    <div className="col-7 d-flex pt-0 justify-content-end card mr-0 flexMySearch-filter-criteria-select-selectBox">
                                        <FormControl fullWidth size="small">
                                            <IbssTimePicker
                                                className="border form-input-box-datetime-picker visit-filter-datepicker py-14 filter-criteria-inputbox mb-0 border-box"
                                                value={DateTime.fromJSDate(this.state.startTime)}
                                                slotProps={{ textField: { size: 'small', error: this.state.startTimeMessage.length > 0, helperText: this.state.startTimeMessage } }}
                                                onChange={time => this.startTimeChanged(time)}
                                                minTime={DateTime.fromJSDate(this.todaysNewDate)}
                                                minutesStep={1}
                                                ampm={false}
                                            />
                                        </FormControl>
                                    </div>
                                </div>
                                <div className="flexMySearch-filter-criteria-border" style={{ marginTop: `${this.state.startTimeMessage.length > 0 ? '26px' : ''}` }}/>
                                <div className="row flexMySearch-filter-criteria-selectBldng-filter">
                                    <Typography variant="body2" className="col-5 card ml-0 pt-0 flexMySearch-filter-criteria-select-label">{this.labels.HubLabelSelectEnd}</Typography>
                                    <div className="col-7 d-flex pt-0 justify-content-end card mr-0 flexMySearch-filter-criteria-select-selectBox">
                                        <FormControl fullWidth size="small">
                                            <IbssTimePicker
                                                className="border form-input-box-datetime-picker visit-filter-datepicker py-14 filter-criteria-inputbox mb-0 border-box"
                                                slotProps={{ textField: { size: 'small', error: this.state.endTimeMessage.length > 0, helperText: this.state.endTimeMessage } }}
                                                value={DateTime.fromJSDate(this.state.endTime)}
                                                onChange={time => this.endTimeChanged(time)}
                                                minTime={isNaN(this.state.startTime.getTime()) ? DateTime.now() : DateTime.fromJSDate(this.state.startTime)}
                                                minutesStep={1}
                                                ampm={false}
                                            />
                                        </FormControl>
                                    </div>
                                </div>
                            </fieldset>
                            <div className="flexMySearch-filter-criteria-border" style={{ marginTop: `${this.state.endTimeMessage.length > 0 ? '26px' : ''}` }} />

                            {/* Space Features */}
                            <fieldset>
                                <Typography variant="body2" className="flexMySearch-filter-criteria-firstLabel">
                                        {this.labels.HubLabelSpaceFeatures}
                                </Typography>
                                <Box sx={{ paddingTop: '20px' }}>
                                    <Paper elevation={0}>
                                        <IbssSwitchLabel id="need-AV" label={<Typography sx={{ color: (theme) => theme.palette.text.primary, ...labelStyles }}>{this.labels.HubLabelNeedAudioVisual}</Typography>} checked={this.state.audioVisual} onChange={e => this.audioVisualChanged(e)} labelPlacement={LabelPlacementType.start} />
                                        <div className="flexMySearch-filter-criteria-border" />                                       
                                        <IbssSwitchLabel 
                                            id="need-presentation-aids" 
                                            checked={this.state.presentationAids} 
                                            onChange={e => this.presentationAidsChanged(e)} 
                                            label={
                                                <Box display={"flex"} alignItems={'center'}>
                                                    <Typography sx={{ color: (theme) => theme.palette.text.primary, ...labelStyles }}>
                                                        {this.labels.HubLabelNeedPresentationAids}
                                                    </Typography>
                                                    <IbssToolTip title={this.labels.funcNeedPresentationAids_D} placement="top" arrow>
                                                        <Box component="span" ml={1} mt={1}>
                                                            <InfoIcon />
                                                        </Box>
                                                    </IbssToolTip>
                                                </Box>
                                            } 
                                            labelPlacement={LabelPlacementType.start} 
                                        />                       
                                        <div className="flexMySearch-filter-criteria-border" />                                                  
                                        <IbssSwitchLabel 
                                            id="need-hearing-aids" 
                                            checked={this.state.hearingAids} 
                                            onChange={e => this.hearingAidsChanged(e)} 
                                            label={
                                                <Box display={"flex"} alignItems={'center'}>
                                                    <Typography sx={{ color: (theme) => theme.palette.text.primary, ...labelStyles }}>
                                                        {this.labels.HubLabelNeedHearingAids}
                                                    </Typography>
                                                    <IbssToolTip title={this.labels.funcNeedHearingAids_D} placement="top" arrow>
                                                        <Box component="span" ml={1} mt={1}>
                                                            <InfoIcon/>
                                                        </Box>
                                                    </IbssToolTip>
                                                </Box>
                                            } 
                                            labelPlacement={LabelPlacementType.start} 
                                        />
                                        <div className="flexMySearch-filter-criteria-border" />

                                        <IbssSwitchLabel id="need-catering" checked={this.state.catering} onChange={e => this.cateringChanged(e)} label={<Typography sx={{ color: (theme) => theme.palette.text.primary, ...labelStyles }}>{this.labels.HubLabelNeedCatering}</Typography>} labelPlacement={LabelPlacementType.start} />
                                        <div className="flexMySearch-filter-criteria-border" />
                                        <IbssSwitchLabel 
                                            id="need-linked-space" 
                                            checked={this.state.linkedSpace} 
                                            onChange={e => this.linkedSpaceChanged(e)} 
                                            label={
                                                <Box display={"flex"} alignItems={'center'}>
                                                    <Typography sx={{ color: (theme) => theme.palette.text.primary, ...labelStyles }}>
                                                        {this.labels.HubLabelNeedLinkedSpace}
                                                    </Typography>
                                                    <IbssToolTip title={this.labels.funcNeedLinkedSpace_D} placement="top" arrow>
                                                        <Box component="span" ml={1} mt={1}>
                                                            <InfoIcon/>
                                                        </Box>
                                                    </IbssToolTip>
                                                </Box>
                                            } 
                                            labelPlacement={LabelPlacementType.start} 
                                        />
                                        <div className="flexMySearch-filter-criteria-border" />
                                        <IbssSwitchLabel 
                                            id="need-layouts" 
                                            checked={this.state.layouts} 
                                            onChange={e => this.layoutsChanged(e)} 
                                            label={
                                                <Box display={"flex"} alignItems={'center'}>
                                                    <Typography sx={{ color: (theme) => theme.palette.text.primary, ...labelStyles }}>
                                                        {this.labels.HubLabelNeedLayouts}
                                                    </Typography>
                                                    <IbssToolTip title={this.labels.funcNeedLayouts_D} placement="top" arrow>
                                                        <Box component="span" ml={1} mt={1}>
                                                            <InfoIcon/>
                                                        </Box>
                                                    </IbssToolTip>
                                                </Box>
                                            } 
                                            labelPlacement={LabelPlacementType.start} 
                                        />
                                        <div className="flexMySearch-filter-criteria-border" />

                                        <Grid container direction="row" alignItems="center" justifyContent={'space-between'}>
                                            <Grid item>
                                                <IbssInputLabel
                                                    htmlFor='for-how-many-people'
                                                    sx={{ color: (theme) => theme.palette.text.primary, ...labelStyles }}>
                                                    {this.labels.HubLabelHowManyPeople}
                                                </IbssInputLabel>
                                            </Grid>
                                            <Grid item>
                                                <IbssTextField
                                                    id="for-how-many-people"
                                                    variant="outlined"
                                                    value={this.state.numberOfPeople}
                                                    onChange={e => this.numberOfPeopleChanged(e)}
                                                    size="small"
                                                    inputProps={{ min: 0, inputMode: 'numeric', pattern: '[0-9]*' }}
                                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, 'width': '75px', 'paddingRight': '18px' }} />
                                            </Grid>
                                        </Grid>

                                        <div className="flexMySearch-filter-criteria-border" />
                                    </Paper>
                                </Box>
                            </fieldset>
                            {/* buttons */}
                            <div className="right-space-box-cont">
                                <div className="d-flex justify-content-center">
                                    <button disabled={disableUpdate} type="button" className=" btn btn-primary btn-md" onClick={() => this.updateClicked()}>{this.labels.HubButtonUpdate}</button>
                                </div>
                                <div className="d-flex justify-content-center">
                                    <a type="button" className="clear-attendees my-2" onClick={() => this.clearClicked()}>{this.labels.HubLabelClearSelections}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </IbssDrawer>
            </>
        );
    }
}

export interface IProps extends IPropsFromState
{
    open: boolean;
    closeClicked: () => void;
    updateSearchResults: (result: ISearchCriteriaResult) => Promise<void>;

    // search criteria
    buildingOptions: number;
    selectedWorkspaceTypes?: string;
    selectedSpaceTypes?: string;
    selectedFloor?: string;
    selectedZone?: string;
    startTime: Date;
    av: boolean;
    presentationAids: boolean;
    hearingAids: boolean;
    catering: boolean;
    linkedSpace: boolean;
    layouts: boolean;
    numberOfPeople: string; // todo: should be a (number | null)?
    End_Date_For_filter_modal: Date;
    isSearchSpace?: boolean
}

export interface IState
{
    // building options
    buildingOptions: Array<IListOption<number>>,
    selectedBuildingOption: number,

    // work-space options
    workSpaceTypeOptions: Array<IListOption<string>>,
    selectedWorkSpaceType: string,

    spaceTypeOptions: Array<IListOption<string>>,
    selectedSpaceTypes: string;

    // location options
    floorTypeOptions: Array<IListOption<number>>,
    selectedFloor: string;

    zoneOptions: Array<IListOption<string>>,
    selectedZone: string,

    // time options
    startTime: Date,
    startTimeMessage: string,
    endTime: Date,
    endTimeMessage: string,
    minTime: Date,
    date: DateTime

    // other options
    audioVisual: boolean,
    presentationAids: boolean,
    hearingAids: boolean,
    catering: boolean,
    linkedSpace: boolean,
    layouts: boolean,
    numberOfPeople: string, // todo: should be a (number | null)?
    userBypassingPolicies: boolean;
}

export interface IListOption<TValue>
{
    label: string,
    value: TValue,
}

export interface ISearchCriteriaResult
{
    buildingId: number;
    workspaceType: (string | null);
    spaceType: (string | null);
    spaceTypeLabel: (string | null);
    floorId: (number | null);
    zone: (string | null);
    startTime: DateTime;
    endTime: DateTime;
    audioVisual: boolean;
    presentationAids: boolean;
    hearingAids: boolean;
    catering: boolean;
    linkedSpace: boolean;
    layouts: boolean;
    numberOfPeople: (number | null);
}

enum TimeValidationError
{
    StartInvalid,
    StartLessThanNow,
    StartGreaterThanEqualToEnd,
    StartDateNotEqualToEndDate,
    EndInvalid,
    EndLessThanNow,
}

class Validator
{
    private get labels() { return appContext().labels; }
    private allowedMinutes: MinuteNumbers[];
    private errors = new Array<TimeValidationError>();

    constructor(allowedMinutes: MinuteNumbers[])
    {
        this.allowedMinutes = allowedMinutes;
    }

    public addError(error: TimeValidationError)
    {
        this.errors.push(error);
    }

    public isValid(): boolean
    {
        return (this.errors.length == 0);
    }

    public validate(startTime: DateTime, endTime: DateTime, nodeId: number): void
    {
        this.errors = new Array<TimeValidationError>();
        const now = DateHelper.now(nodeId).plus({ minutes: 1 });
        if (!startTime.isValid)
        {
            this.addError(TimeValidationError.StartInvalid);
        }
        if (!endTime.isValid)
        {
            this.addError(TimeValidationError.EndInvalid);
        }
        
        if (startTime.isValid)
        {
            const snappedStartTime = startTime.snapToMinute(this.allowedMinutes);
            if (startTime < now)
            {
                this.addError(TimeValidationError.StartLessThanNow);
            }
        }

        if (endTime.isValid)
        {
            const snappedEndTime = endTime.snapToMinute(this.allowedMinutes);
            if (endTime < now)
            {
                this.addError(TimeValidationError.EndLessThanNow);
            }
        }

        if (startTime.isValid && endTime.isValid)
        {
            if (startTime >= endTime)
            {
                this.addError(TimeValidationError.StartGreaterThanEqualToEnd);
            }
            if (startTime.date().diff(endTime.date()).milliseconds != 0)
            {
                this.addError(TimeValidationError.StartDateNotEqualToEndDate);
            }
        }
    }

    public toStartTimeMessage(): string
    {
        let messages = new Array<string>();
        this.errors.map(i =>
        {
            switch (i)
            {
                case TimeValidationError.StartInvalid:
                    messages.push(this.labels.HubLabelMustBeAValidDateAndTime);
                    break;
                case TimeValidationError.StartLessThanNow:
                    messages.push(this.labels.HubLabelMustNotBeInThePast);
                    break;
                case TimeValidationError.StartGreaterThanEqualToEnd:
                    messages.push(this.labels.HubLabelMustBeBeforeTheEndTime);
                    break;
                case TimeValidationError.StartDateNotEqualToEndDate:
                    messages.push(this.labels.HubLabelMustBeOnTheSameDayAsTheEndTime);
                    break;
            }
        });
        return messages.join(" ");
    }

    public toEndTimeMessage(): string
    {
        let messages = new Array<string>();
        this.errors.map(i =>
        {
            switch (i)
            {
                case TimeValidationError.EndInvalid:
                    messages.push(this.labels.HubLabelMustBeAValidDateAndTime);
                    break;
                case TimeValidationError.EndLessThanNow:
                    messages.push(this.labels.HubLabelMustNotBeInThePast);
                    break;
                case TimeValidationError.StartGreaterThanEqualToEnd:
                    messages.push(this.labels.HubLabelMustBeAfterTheStartTime);
                    break;
                case TimeValidationError.StartDateNotEqualToEndDate:
                    messages.push(this.labels.HubLabelMustBeOnTheSameDayAsTheStartTime);
                    break;
            }
        });
        return messages.join(" ");
    }

    public toPopupMessage(): string
    {
        let messages = new Array<string>();
        this.errors.map(i =>
        {
            switch (i)
            {
                case TimeValidationError.StartInvalid:
                    messages.push(this.labels.HubLabelStartTimeMustBeAValidDateAndTime);
                    break;
                case TimeValidationError.StartLessThanNow:
                    messages.push(this.labels.HubLabelStartTimeMustNotBeInThePast);
                    break;
                case TimeValidationError.StartGreaterThanEqualToEnd:
                    messages.push(this.labels.HubLabelStartTimeMustBeBeforeTheEndTime);
                    break;
                case TimeValidationError.StartDateNotEqualToEndDate:
                    messages.push(this.labels.HubLabelStartTimeMustBeOnTheSameDayAsTheEndTime);
                    break;
                case TimeValidationError.EndInvalid:
                    messages.push(this.labels.HubLabelEndTimeMustBeAValidDateAndTime);
                    break;
                case TimeValidationError.EndLessThanNow:
                    messages.push(this.labels.HubLabelEndTimeMustNotBeInThePast);
                    break;
            }
        });
        return `<ul>${messages.map(i => `<li>${i}</li>`).join("")}</ul>`;
    }
}
