import { appContext } from '../../../../AppContext';
import { IFloor } from '../../../../Providers.Api/Models';
import { Grid,SelectChangeEvent } from '@mui/material';
import Helper from '../../../../Common/Helper';
import IbssInputDropDown from '../../../../Components/Inputs/SelectList/IbssInputDropDown';
import IbssFormControl from '../../../../Components/Forms/FormControl/IbssFormControl';
import { IbssComponent } from '../../../../Components/Core/BaseComponent/IbssComponent';
import IbssDatePicker from '../../../../Components/Inputs/DatePicker/IbssDatePicker';
import { DateHelper } from '../../../../Common/DateHelper';
import { DateTime } from 'luxon';
import IbssTimePicker from '../../../../Components/Inputs/TimePicker/IbssTimePicker';

class SearchSpaceFilter extends IbssComponent<IProps, IState>
{
    private get labels() { return appContext().labels; }
    private get local() { return appContext().localStorageProvider; }
    private get apiClient() { return appContext().apiClient; }

    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
            buildingOptions: [],
            workSpaceTypeOptions: [],
            spaceTypeOptions: [],
            floorTypeOptions: [],
            zoneOptions: [],
            selectedBuildingOption: props.buildingOptions,
            selectedWorkSpaceType: props.selectedWorkspaceTypes,
            selectedSpaceTypes: props.selectedSpaceTypes,
            selectedFloor: props.selectedFloor,
            selectedZone: props.selectedZone,
        };

    }

    public async componentDidMount(): Promise<void>
    {        
        const userPreferences = this.local.getUserPreferences();
        const selectedZone = userPreferences?.Nodes?.find(building => building.NodeId === this.state.selectedBuildingOption)?.DefaultZone?.toString() ?? "Any";

        this.populateBuildings(this.state.selectedBuildingOption);
        this.populateWorkSpaces(this.state.selectedBuildingOption, this.state.selectedWorkSpaceType);
        this.populateSpaceTypes(this.state.selectedBuildingOption, this.state.selectedSpaceTypes);
        this.populateFloors(this.state.selectedBuildingOption, this.state.selectedFloor);
        this.populateZones(this.state.selectedFloor, selectedZone);
    }

    private populateBuildings(selectedValue: number): void
    {
        const buildings = Helper.getAllBuildingsData().sort((a, b) => a.Name.localeCompare(b.Name));
        const options = buildings.map(i => ({ label: i.Name, value: i.Node_Id }));
        this.setState({ buildingOptions: options, selectedBuildingOption: selectedValue });
    }

    private populateWorkSpaces(selectedBuildingId: number, selectedWorkSpace: string): void
    {
        const workSpaces = Helper.getWorkSpaceTypesByNodeId(selectedBuildingId);

        const options = workSpaces
            .filter(i => i.Name != null)
            .map(i => ({ label: i.Label, value: i.Name }));

        options.unshift({ label: this.labels.HubLabelAny, value: this.labels.HubLabelAny });
        this.setState({ workSpaceTypeOptions: options, selectedWorkSpaceType: selectedWorkSpace });
        
    }

    private populateSpaceTypes(selectedBuildingId: number, selectedSpaceType: string): void
    {
        const spaceTypes = Helper.getSpaceTypesByNodeId(selectedBuildingId);

        const options = spaceTypes.result
            .filter(i => i.Name != null)
            .map(i => ({ label: i.Label, value: i.Name }))
            .sort((a, b) => a.label.localeCompare(b.label)); 
            
        options.unshift({ label: this.labels.HubLabelAny, value: this.labels.HubLabelAny });
        this.setState({ spaceTypeOptions: options, selectedSpaceTypes: selectedSpaceType });
    }

    private populateFloors(selectedBuildingId: number, selectedFloor: string): void
    {
        const floors: IFloor[] = Helper.getFloorsByBuildingId(selectedBuildingId);

        const options = floors
            .map(i => ({ label: i.Node_Name, value: i.Node_Id.toString() }))
            .sort((a, b) => (a.label < b.label ? - 1 : 1));

        options.unshift({ label: this.labels.HubLabelAny, value: this.labels.HubLabelAny });
        this.setState({ floorTypeOptions: options, selectedFloor: selectedFloor });
    }

    private async populateZones(selectedFloor: string, selectedZone: string): Promise<void>
    {
        if (selectedFloor === "Any")
        {
            this.setState({ zoneOptions: [{ label: this.labels.HubLabelAny, value: this.labels.HubLabelAny }], selectedZone: selectedZone });
            return;
        }

        const zonesResponse = await this.apiClient.spaceZones.getMultiple(parseInt(selectedFloor), true);        
        const options = zonesResponse.map(i => ({ label: i.Meta_Loc_Zone, value: i.Space_Zone_Id.toString() }));
        const zone = selectedZone === "Any"? selectedZone : options.find( options => options.value === selectedZone)?.value ?? '';

        options.unshift({ label: this.labels.HubLabelAny, value: this.labels.HubLabelAny });
        this.setState({ zoneOptions: options, selectedZone: zone });
    }

    private async buildingChanged(event: SelectChangeEvent<number>): Promise<void> 
    {   
        const userPreferences = this.local.getUserPreferences();
        const selectedBuildingId = event.target.value as number;
        const spaceTypes = Helper.getSpaceTypesByNodeId(selectedBuildingId);
        const selectedSpace  = spaceTypes.result[0]?.Name;
        const selectedFloor = userPreferences.Nodes.find(building => building.NodeId === selectedBuildingId)?.DefaultFloor?.toString() ?? "Any";
        const selectedZone = userPreferences?.Nodes?.find(building => building.NodeId === selectedBuildingId)?.DefaultZone?.toString() ?? "Any";        
        this.populateWorkSpaces(selectedBuildingId, "Any");
        this.populateSpaceTypes(selectedBuildingId, selectedSpace);
        this.populateFloors(selectedBuildingId, selectedFloor);
        this.populateZones(selectedFloor, selectedZone);

        this.setState({
            selectedBuildingOption: selectedBuildingId,
        });

        this.props.changeBuildingId(selectedBuildingId);
        this.props.changeFloor(selectedFloor);
        this.props.changeSpaceType(selectedSpace);
        this.props.changeWorkSpaceType("Any");

        const defaultZone = this.state.zoneOptions.find(zone => zone.value === selectedZone)?.label ?? "Any";
        this.props.changeZone(defaultZone);
    }

    private workSpaceChanged(event: SelectChangeEvent): void
    {
        let spaceType = (event.target.value == "Any" ? this.state.spaceTypeOptions[1]?.value ?? "Any" : "Any");
        this.setState({ selectedWorkSpaceType: event.target.value, selectedSpaceTypes: spaceType });
        this.props.changeWorkSpaceType(event.target.value);
        this.props.changeSpaceType(spaceType);
    }

    private spaceTypeChanged(event: SelectChangeEvent): void
    {
        let workSpaceType = (event.target.value == "Any" ? this.state.workSpaceTypeOptions[1]?.value ?? "Any" : "Any");
        this.setState({ selectedSpaceTypes: event.target.value, selectedWorkSpaceType: workSpaceType });
        this.props.changeSpaceType(event.target.value);
        this.props.changeWorkSpaceType(workSpaceType);
    }

    private floorChanged(event: SelectChangeEvent): void
    {
        this.setState({ selectedFloor: event.target.value });
        this.populateZones(event.target.value, "Any");
        this.props.changeFloor(event.target.value);
    }

    private zoneChanged(event: SelectChangeEvent): void
    {
        this.setState({ selectedZone: event.target.value });
        const zone = this.state.zoneOptions.find(zone => zone.value ===  event.target.value)?.label ?? "Any"
        this.props.changeZone(zone);
    }
   
    public render(): JSX.Element
    {
        return (
            <Grid container spacing={3}>
                <Grid item xs={1.5}>
                    <IbssFormControl fullWidth={true} onClick={this.props.showSearchCriteria}>                
                        <IbssTimePicker
                            label={this.labels.HubLabelTime}
                            value={this.props.startTime}
                            minutesStep={1}
                            ampm={false}
                            readOnly={true}
                        />
                    </IbssFormControl>
                </Grid>
                <Grid item xs={1.75}>
                    <IbssFormControl fullWidth={true}  onClick={this.props.showSearchCriteria}>                   
                        <IbssDatePicker
                            label={this.labels.HubLabelDate}
                            value={this.props.startTime}
                            minDate={DateHelper.now()}
                            readOnly={true}
                        />
                    </IbssFormControl>
                </Grid>
                <Grid item xs={1.75}>
                    <IbssFormControl fullWidth={true}>
                        <IbssInputDropDown
                            id="buildingSelection"
                            inputLabel={this.labels.HubLabelBuilding}
                            fullWidth={true}
                            options={this.state.buildingOptions}
                            value={this.state.selectedBuildingOption}
                            onChange={(e: SelectChangeEvent<number>) => this.buildingChanged(e)}
                        />
                    </IbssFormControl>
                </Grid>
                <Grid item xs={1.75}>
                    <IbssFormControl fullWidth={true}>
                        <IbssInputDropDown
                            id="floorSelection"
                            inputLabel={this.labels.HubLabelFloor}
                            fullWidth={true}
                            options={this.state.floorTypeOptions}
                            value={this.state.selectedFloor}
                            onChange={(e: SelectChangeEvent) => this.floorChanged(e)}
                        />
                    </IbssFormControl>
                </Grid>
                <Grid item xs={1.5}>
                    <IbssFormControl fullWidth={true}>
                        <IbssInputDropDown
                            id="zoneSelection"
                            inputLabel={this.labels.HubLabelZone}
                            fullWidth={true}
                            options={this.state.zoneOptions}
                            value={this.state.selectedZone}
                            onChange={(e: SelectChangeEvent) => this.zoneChanged(e)}
                        />
                    </IbssFormControl>
                </Grid>
                <Grid item xs={1.75}>
                    <IbssFormControl fullWidth={true}>
                        <IbssInputDropDown
                            id="workTypeSelection"
                            inputLabel={this.labels.HubLabelworkType}
                            fullWidth={true}
                            options={this.state.workSpaceTypeOptions}
                            value={this.state.selectedWorkSpaceType}
                            onChange={(e: SelectChangeEvent) => this.workSpaceChanged(e)}
                        />
                    </IbssFormControl>
                </Grid>
                <Grid item xs={1.5}>
                    <IbssFormControl fullWidth={true}>
                        <IbssInputDropDown
                            id="spaceTypeSelection"
                            inputLabel={this.labels.HubLabelSpaceType}
                            fullWidth={true}
                            options={this.state.spaceTypeOptions}
                            value={this.state.selectedSpaceTypes}
                            onChange={(e: SelectChangeEvent) => this.spaceTypeChanged(e)}
                        />
                    </IbssFormControl>
                </Grid>
            </Grid>
        )
    }
}
export default SearchSpaceFilter;

export interface IProps
{
    buildingOptions: number;
    selectedWorkspaceTypes: string;
    selectedSpaceTypes: string;
    selectedFloor: string;
    selectedZone: string;
    startTime: DateTime
    changeBuildingId: (buildingId: number) => void,
    changeFloor: (floor: string) => void,
    changeSpaceType: (spaceType: string)=> void,
    changeWorkSpaceType: (spaceWorkType: string)=> void,
    changeZone: (zone: string) => void,
    showSearchCriteria : ()=> void 
}

export interface IState
{
    buildingOptions: Array<IListOption<number>>,
    workSpaceTypeOptions: Array<IListOption<string>>,
    spaceTypeOptions: Array<IListOption<string>>,
    floorTypeOptions: Array<IListOption<string>>,
    zoneOptions: Array<IListOption<string>>,
    selectedBuildingOption: number,
    selectedWorkSpaceType: string,
    selectedSpaceTypes: string;
    selectedFloor: string;
    selectedZone: string,
}

export interface IListOption<TValue>
{
    label: string,
    value: TValue,
}
