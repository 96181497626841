import { Typography } from '@mui/material';
import { IbssComponent } from '../../../../Components/Core/BaseComponent/IbssComponent';
import IbssSvgIcon from '../../../../Components/Icons/SvgIcon/IbssSvgIcon';
import { Icons } from '../../../../Common/AllsvgIcons';
import { getBuildingNameUsingBuildingId, getFloorNameUsingFloorAndBuildingId } from '../../../../Common/Helper';
import ExistingSpaceLayoutBox from './ExistingSpaceLayoutBox';
import AddLayoutBox from './AddLayoutBox';
import EditLayoutForm from './EditLayoutForm';
import { ISeatingArrangement, ISpaceLayout } from './ListSpaceLayouts';
import IbssButton from '../../../../Components/Buttons/Button/IbssButton';
import { appContext } from '../../../../AppContext';
import FloorPlanPopup from './FloorPlanPopup';
import UnsavedChangesModal from './UnsavedChangesModal';
import LoadingOverlay from '../../../../Components/Navigation/LoadingOverlay/LoadingOverlay';
import { PagedResponse } from '../../../../Providers.Api/Models';
import { SpaceLayouts } from './DataModels';
import ConfirmModal from '../../../../Components/Dialogs/ConfirmDialog/ConfirmModal';

class EditSpaceLayoutPanel extends IbssComponent<IProps, IState>
{

    private get labels() { return appContext().labels; }

    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
            selectedLayout: null,
            showForm: false,
            layoutForEdit: { Name: '', SeatingArrangements: [], Space_Id: '' },
            otherLayoutsForSpace: [],
            isNewLayout: true,
            showFloorPlan: false,
            showDeleteLayoutConfiramtionModal: false,
            layoutToDelete: null,
            showUnsavedChangesModal: false,
            savingChanges: false,
            showLinkedSpaceDeleteWarningModal: false
        };
    };

    public componentDidMount(): void
    {
        if (this.props.layouts?.length > 0)
        {
            this.setState({
                layoutForEdit: this.props.layouts.filter((x: { Space_Id: string; }) => x.Space_Id == this.props.spaceId)[0],
                otherLayoutsForSpace: this.props.layouts.filter((x: { Space_Id: string; }) => x.Space_Id != this.props.spaceId)
            });
        }
    }

    private createNewLayout(): void
    {
        this.setState({
            isNewLayout: true,
            showForm: true,
            selectedLayout: {
                original: {
                    Style: '',
                    Capacity: 1,
                    Setup: 0,
                    Breakdown: 0,
                },
                updated: {
                    Style: '',
                    Capacity: 1,
                    Setup: 0,
                    Breakdown: 0,
                }
            },
        });
    }

    private cancelLayoutChanges(): void
    {
        this.setState({
            showForm: false,
            selectedLayout: null,
        });
    }

    private addNewLayout(): void
    {
        this.props.layoutEdited();
        this.setState({
            showForm: false,
            selectedLayout: null,
        });

        if (this.state.selectedLayout)
        {
            this.setState({ layoutForEdit: { ...this.state.layoutForEdit, SeatingArrangements: [...this.state.layoutForEdit.SeatingArrangements, this.state.selectedLayout?.updated] } });
        }
    }

    private updateLayout(): void
    {
        this.props.layoutEdited();
        const updatedSeatingArrangement = this.state.layoutForEdit.SeatingArrangements.map(layout => layout.Style == this.state.selectedLayout?.original.Style ? this.state.selectedLayout.updated : layout)
        this.setState({
            showForm: false,
            layoutForEdit: { ...this.state.layoutForEdit, SeatingArrangements: updatedSeatingArrangement },
            selectedLayout: null,
        });
    }

    private deleteLayout(): void
    {
        this.props.layoutEdited();
        const updatedSeatingArrangement = this.state.layoutForEdit.SeatingArrangements.filter(layout => layout.Style != this.state.layoutToDelete)
        this.setState({
            showDeleteLayoutConfiramtionModal: false,
            layoutForEdit: { ...this.state.layoutForEdit, SeatingArrangements: updatedSeatingArrangement },
        });
    }

    private spaceLayoutSelected(layoutName: string): void
    {
        const selectedLayout = this.state.layoutForEdit.SeatingArrangements.filter(x => x.Style == layoutName)[0]
        this.setState({
            isNewLayout: false,
            showForm: true,
            selectedLayout: {
                original: {
                    Style: selectedLayout.Style,
                    Capacity: selectedLayout.Capacity,
                    Setup: selectedLayout.Setup,
                    Breakdown: selectedLayout.Breakdown,
                },
                updated: {
                    Style: selectedLayout.Style,
                    Capacity: selectedLayout.Capacity,
                    Setup: selectedLayout.Setup,
                    Breakdown: selectedLayout.Breakdown,
                }
            },
        });
    }

    private ignoreChanges(): void
    {
        this.props.clearSelectedSpaces();
        this.props.ignoreUnsavedChanges();
    }

    private closeUnsavedChangesModal(): void
    {
        this.setState({ showUnsavedChangesModal: false });
        this.props.closeUnsavedChangesModal();
    }

    private cancelSpaceChanges(): void
    {
        if (this.props.unsavedChanges)
        {
            this.setState({ showUnsavedChangesModal: true });
        }
        else
        {
            this.props.clearSelectedSpaces();
        }
    }

    private async save(): Promise<void>
    {

        if (this.props.isLinkedSpace)
        {
            this.setState({ savingChanges: true });
            const spaceIds = this.props.spaceId.split(';');
            try
            {
                const spaces = await appContext().ibssApiClientV2.v2.configuration.byNodeid.spaces.get<PagedResponse<SpaceLayouts[]>>({
                    nodeId: this.props.floorId,
                    filter: spaceIds.map(x => `Space_Id eq '${x}'`).join(' or '),
                    recursive: true,
                    select: SpaceLayouts,
                });
                let i = 0;
                while (i < spaceIds.length)
                {
                    const space = spaces.value.filter(x => x.Space_Id == spaceIds[i])[0]

                    const spaceLayouts = space.Space_Layout == "" ? { Layouts: [] } : JSON.parse(space.Space_Layout);
                    const updatedSpaceLayouts = spaceLayouts.Layouts.map((layout: { Space_Id: string; }) => layout.Space_Id == this.props.spaceId ? { Name: this.props.spaceName, Space_Id: this.props.spaceId, SeatingArrangements: this.state.layoutForEdit.SeatingArrangements } : layout)
                    await appContext().ibssApiClientV2.v2.configuration.byNodeid.spaces.bySpaceId.patch({
                        nodeId: this.props.floorId,
                        spaceId: space.Space_Id,
                        body: { Space_Layout: JSON.stringify({ Layouts: updatedSpaceLayouts }), ConcurrencyStamp: space.ConcurrencyStamp }
                    });

                    i = i + 1;
                }
                this.setState({ savingChanges: false });
                this.props.clearSelectedSpaces();
                this.props.onChangesSaved();
            } catch (error)
            {
                this.setState({ savingChanges: false });
            }
        }
        else
        {
            try
            {
                this.setState({ savingChanges: true });
                await appContext().ibssApiClientV2.v2.configuration.byNodeid.spaces.bySpaceId.patch({
                    nodeId: this.props.floorId,
                    spaceId: this.props.spaceId,
                    body: { Space_Layout: JSON.stringify({ Layouts: [...this.state.otherLayoutsForSpace, { Name: this.props.spaceName, Space_Id: this.props.spaceId, SeatingArrangements: this.state.layoutForEdit.SeatingArrangements }] }), ConcurrencyStamp: this.props.concurrencyStamp }
                });
                this.setState({ savingChanges: false });
                this.props.clearSelectedSpaces();
                this.props.onChangesSaved();
            } catch (error)
            {
                this.setState({ savingChanges: false });
            }
        }
    }

    private async deleteLinkedSpace(): Promise<void>
    {
        this.setState({ savingChanges: true });
        const spaceIds = this.props.spaceId.split(';')
        const spaces = await appContext().ibssApiClientV2.v2.configuration.byNodeid.spaces.get<PagedResponse<SpaceLayouts[]>>({
            nodeId: this.props.floorId,
            filter: spaceIds.map(x => `Space_Id eq '${x}'`).join(' or '),
            recursive: true,
            select: SpaceLayouts,
        });
        let i = 0
        try
        {
            while (i < spaceIds.length)
            {
                const space = spaces.value.filter(x => x.Space_Id == spaceIds[i])[0];
                const spaceLayouts = space.Space_Layout == "" ? { Layouts: [] } : JSON.parse(space.Space_Layout);
                const updatedSpaceLayouts = spaceLayouts.Layouts.filter((x: { Space_Id: string; }) => x.Space_Id != this.props.spaceId)

                await appContext().ibssApiClientV2.v2.configuration.byNodeid.spaces.bySpaceId.patch({
                    nodeId: this.props.floorId,
                    spaceId: space.Space_Id,
                    body: { Space_Layout: JSON.stringify({ Layouts: updatedSpaceLayouts }), ConcurrencyStamp: space.ConcurrencyStamp }
                });

                i = i + 1;
            }
            this.setState({ savingChanges: false });
            this.props.clearSelectedSpaces();
            this.props.onChangesSaved();
        } catch (error)
        {
            this.setState({ savingChanges: false });
        }
        this.setState({ showLinkedSpaceDeleteWarningModal: false })
    }

    public render(): JSX.Element
    {
        return (
            <div className='m-3'>
                {
                    this.state.savingChanges &&
                    <LoadingOverlay />
                }
                <Typography variant='h4'>{this.props.spaceName}</Typography>
                <div>{this.labels.HubLabelSpaceId}: {this.props.spaceId}</div>
                <div className='d-flex mt-1'>
                    <Typography display={'flex'} alignItems={'center'} className="headings mr-2">
                        <IbssSvgIcon fontSize="small" className="mr-1">
                            {Icons.building}
                        </IbssSvgIcon>
                        <span className="mt-3px">{getBuildingNameUsingBuildingId(this.props.buildingId)}</span>
                    </Typography>
                    <Typography display={'flex'} alignItems={'center'} className="headings mr-2">
                        <IbssSvgIcon fontSize="inherit" className="mr-2">
                            {Icons.FloorIcon}
                        </IbssSvgIcon>
                        <span className="mt-3px">{this.props.floorName}</span>
                    </Typography>
                    <Typography display={'flex'} alignItems={'center'} className="headings mr-2">
                        <IbssSvgIcon fontSize="inherit" className="mr-2">
                            {Icons.ZoneIcon}
                        </IbssSvgIcon>
                        <span className="mt-3px">{this.props.zone}</span>
                    </Typography>
                </div>
                <IbssButton className='mt-1 mb-1' onClick={() => this.setState({ showFloorPlan: true })}>{this.labels.funcSpaceLayoutViewMap_S}</IbssButton>
                <div className='mb-1'>{this.labels.funcSpaceLayouts_S}</div>
                {
                    !this.state.showForm && this.state.layoutForEdit && this.state.layoutForEdit.SeatingArrangements.map((x: { Style: string; }) => (
                        <div className='mb-1' key={x.Style}>
                            <ExistingSpaceLayoutBox spaceLayoutName={x.Style} canBeDeleted={this.state.layoutForEdit.SeatingArrangements.length > 1} onDeleteSelected={() => this.setState({ showDeleteLayoutConfiramtionModal: true, layoutToDelete: x.Style })} onSpaceLayoutSelected={(spaceLayoutName: string) => this.spaceLayoutSelected(spaceLayoutName)} />
                        </div>
                    ))
                }
                {
                    !this.state.showForm &&
                    <AddLayoutBox onClick={() => this.createNewLayout()} />
                }
                {
                    this.state.showForm &&
                    <EditLayoutForm
                        newLayout={this.state.isNewLayout}
                        name={this.state.selectedLayout?.updated.Style ?? ''}
                        capacity={this.state.selectedLayout?.updated.Capacity ?? 0}
                        setup={this.state.selectedLayout?.updated.Setup ?? 0}
                        breakdown={this.state.selectedLayout?.updated.Breakdown ?? 0}
                        onNameChange={(value) => this.state.selectedLayout ? this.setState({ selectedLayout: { ...this.state.selectedLayout, updated: { ...this.state.selectedLayout.updated, Style: value } } }) : {}}
                        onCapacityChange={(value) => this.state.selectedLayout ? this.setState({ selectedLayout: { ...this.state.selectedLayout, updated: { ...this.state.selectedLayout.updated, Capacity: parseInt(value) } } }) : {}}
                        onSetupChange={(value) => this.state.selectedLayout ? this.setState({ selectedLayout: { ...this.state.selectedLayout, updated: { ...this.state.selectedLayout.updated, Setup: parseInt(value) } } }) : {}}
                        onBreakdownChange={(value) => this.state.selectedLayout ? this.setState({ selectedLayout: { ...this.state.selectedLayout, updated: { ...this.state.selectedLayout.updated, Breakdown: parseInt(value) } } }) : {}}
                        onCancel={() => this.cancelLayoutChanges()}
                        onAddLayout={() => this.addNewLayout()}
                        onUpdateLayout={() => this.updateLayout()}
                        nameAlreadyExists={this.state.selectedLayout ? this.state.layoutForEdit.SeatingArrangements.map(x => x.Style).includes(this.state.selectedLayout.updated.Style) && this.state.selectedLayout.original.Style != this.state.selectedLayout.updated.Style : false}
                    />
                }
                {
                    !this.state.showForm && this.props.isLinkedSpace &&
                    <IbssButton className="mt-1" sx={{ width: '48%' }} color='error' onClick={() => this.setState({ showLinkedSpaceDeleteWarningModal: true })}>{this.labels.funcDeleteLinkedSpace_S}</IbssButton>
                }
                {
                    !this.state.showForm &&
                    <div className='d-flex justify-content-between mt-2'>
                        <IbssButton sx={{ width: '48%' }} color='secondary' variant='contained' onClick={() => this.cancelSpaceChanges()}>{this.labels.HubButtonCancel}</IbssButton>
                        <IbssButton sx={{ width: '48%' }} color='primary' variant='contained' onClick={() => this.save()}>{this.labels.HubButtonSave}</IbssButton>
                    </div>
                }
                <FloorPlanPopup show={this.state.showFloorPlan} floorId={this.props.floorId} close={() => this.setState({ showFloorPlan: false })} spacesToHighlight={this.props.isLinkedSpace ? this.props.spaceId.split(';') : [this.props.spaceId]} />
                <ConfirmModal
                    show={this.state.showDeleteLayoutConfiramtionModal}
                    modalHeading={this.labels.funcDeleteLinkedSpacePopupTitle_S}
                    modalMessage={this.labels.funcDeleteLayoutWarning_Message}
                    handleModal={() => this.setState({ showDeleteLayoutConfiramtionModal: false, layoutToDelete: null })}
                    okButton={() => this.deleteLayout()}
                    cancelButtonText={this.labels.funcSpaceLayoutsNo_S}
                    confirmButtonColor={'error'}
                    confirmButtonText={this.labels.funcSpaceLayoutsYes_S}
                />

                <UnsavedChangesModal show={this.state.showUnsavedChangesModal || this.props.showUnsavedChangesModal} close={() => this.closeUnsavedChangesModal()} save={() => this.save()} ignoreChanges={() => this.ignoreChanges()} />
                <ConfirmModal
                    show={this.state.showLinkedSpaceDeleteWarningModal}
                    modalHeading={this.labels.funcDeleteLayout_S}
                    modalMessage={this.labels.funcDeleteLinkedSpacePopupMessage_S}
                    handleModal={() => this.setState({ showLinkedSpaceDeleteWarningModal: false })}
                    okButton={() => this.deleteLinkedSpace()}
                    cancelButtonText={this.labels.funcSpaceLayoutsNo_S}
                    confirmButtonColor={'error'}
                    confirmButtonText={this.labels.funcSpaceLayoutsYes_S}
                />
            </div>
        )
    }
}

export default EditSpaceLayoutPanel;

export interface IProps
{
    buildingId: number;
    floorId: number;
    floorName: string;
    spaceName: string;
    spaceId: string;
    zone: string;
    spaceLayouts: string;
    layouts: ISpaceLayout[];
    concurrencyStamp: string;
    layoutEdited: () => void;
    clearSelectedSpaces: () => void;
    showUnsavedChangesModal: boolean;
    ignoreUnsavedChanges: () => void;
    closeUnsavedChangesModal: () => void;
    unsavedChanges: boolean;
    onChangesSaved: () => void;
    isLinkedSpace: boolean;
}

export interface IState
{
    selectedLayout: ISelectedLayout | null;
    showForm: boolean;
    layoutForEdit: ISpaceLayout;
    otherLayoutsForSpace: ISpaceLayout[]
    isNewLayout: boolean;
    showFloorPlan: boolean;
    showDeleteLayoutConfiramtionModal: boolean;
    layoutToDelete: string | null;
    showUnsavedChangesModal: boolean;
    savingChanges: boolean;
    showLinkedSpaceDeleteWarningModal: boolean;
}

export interface ISelectedLayout
{
    original: ISeatingArrangement;
    updated: ISeatingArrangement;
}