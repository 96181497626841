import { DateTime } from "luxon";
import { RouteHelper } from "../../../Common/RouteHelper";
import { StringHelper } from "../../../Common/StringHelper";

export class BaseQueryParams
{
    // note: we must prefix properties that are not meant for the the query-string with an underscore
    private _route = new RouteHelper();
    private _string = new StringHelper();

    public building?: number;

    private queryProperties(property: (name: string) => void)
    {
        for (const propertyName in this)
        {
            if (propertyName.startsWith('_')) // exclude properties that are not meant for the query-string
            {
                continue;
            }
            property(propertyName);
        }
    }

    public toQueryString(): string
    {
        const url = window.location.href;
        const urlObject = new URL(url);
        const queryParams = urlObject.searchParams;

        this.queryProperties(propertyName =>
        {
            const propertyKey = propertyName as keyof this;
            const queryName = this._string.toKebabCase(propertyName);
            const queryValue = this.serialise(propertyKey, this[propertyKey]);

            if (queryValue != null)
            {
                queryParams.set(queryName, queryValue);
            }
            else
            {
                queryParams.delete(queryName);
            }
        });
        return queryParams.toString();
    }

    public serialise(propertyName: keyof this, propertyValue: any): string | null
    {
        const basePropertyName = propertyName as keyof BaseQueryParams;
        if (propertyValue == null)
        {
            return null;
        }
        else if (typeof (propertyValue) == 'string')
        {
            return propertyValue;
        }
        else if (basePropertyName == 'building')
        {
            return this.serialiseNumber(propertyValue);
        }
        else
        {
            throw new Error("You must override 'serialise' and handle non-string types.");
        }
    }

    public serialiseNumber(propertyValue: number | null): string | null
    {
        return (propertyValue != null ? propertyValue.toString() : null);
    }

    public serialiseBoolean(propertyValue: boolean | null): string | null
    {
        return (propertyValue == true ? '1' : (propertyValue == false ? '0' : null));
    }

    public serialiseDateTime(propertyValue: DateTime | null): string | null
    {
        return (propertyValue?.toUrl() ?? null);
    }

    public serialiseDate(propertyValue: DateTime | null): string | null
    {
        return (propertyValue?.toUrlDate() ?? null);
    }

    public serialiseTime(propertyValue: DateTime | null): string | null
    {
        return (propertyValue?.toUrlTime() ?? null);
    }

    public parseQueryString(): void
    {
        const queryParams = this._route.getQueryParams();
        this.queryProperties(propertyName =>
        {
            const propertyKey = propertyName as keyof this;
            const queryName = this._string.toKebabCase(propertyName);
            const rawQueryValue = queryParams.get(queryName) ?? null;
            const queryValue = this.deserialise(propertyKey, rawQueryValue);
            this[propertyKey] = (queryValue as any) ?? undefined;
        });
    }

    public deserialise(propertyName: keyof this, queryValue: string | null): any
    {
        const basePropertyName = propertyName as keyof BaseQueryParams;
        if (basePropertyName == 'building')
        {
            return this.deserialiseNumber(queryValue);
        }
        else
        {
            return queryValue;
        }
    }

    public deserialiseNumber(queryValue: string | null): number | null
    {
        if (queryValue == null)
        {
            return null;
        }
        var valueAsNumber = new Number(queryValue).valueOf();
        if (isNaN(valueAsNumber))
        {
            return null;
        }
        return valueAsNumber;
    }

    public deserialiseBoolean(queryValue: string | null): boolean | null
    {
        if (queryValue == null)
        {
            return null;
        }
        else if (queryValue == '0')
        {
            return false;
        }
        else if (queryValue == '1')
        {
            return true;
        }
        else
        {
            return null;
        }
    }

    public deserialiseDateTime(queryValue: string | null): DateTime | null
    {
        return this.deserialiseDateTimeOrDateOrTime(queryValue, 'yyyy-MM-dd_HH-mm-ss');
    }

    public deserialiseDate(queryValue: string | null): DateTime | null
    {
        return this.deserialiseDateTimeOrDateOrTime(queryValue, 'yyyy-MM-dd');
    }

    public deserialiseTime(queryValue: string | null): DateTime | null
    {
        return this.deserialiseDateTimeOrDateOrTime(queryValue, 'HH-mm-ss');
    }

    private deserialiseDateTimeOrDateOrTime(queryValue: string | null, format: string): DateTime | null
    {
        if (queryValue == null)
        {
            return null;
        }
        var valueAsDateTime = DateTime.fromFormat(queryValue, format);
        if (!valueAsDateTime.isValid)
        {
            return null;
        }
        return valueAsDateTime;
    }

    public copy(): BaseQueryParams
    {
        const copy = { ...this };
        return copy;
    }
}
