import { Component } from "react";
import "../../styles/css/dashboard.scss";
import "../../App.css";
import { connect } from "react-redux";
import apis from "../../Providers.Api/apis";
import { LogOutReason } from "../../Providers.Api/Authentication";
import { appContext } from "../../AppContext";
import { IUserDetails } from "../../Providers.LocalStorage/Models";
import { RouteComponentProps } from "react-router-dom";
import Helper from "../../Common/Helper";

class Dashboard extends Component<RouteComponentProps, any>
{
    private get labels() { return appContext().labels; }
    private get msal() { return appContext().msalProvider; }
    private get session() { return appContext().sessionStorageProvider; }
    private get local() { return appContext().localStorageProvider; }
    private get appState() { return appContext().state; }

    constructor(props: RouteComponentProps)
    {
        super(props);
        this.state = {};
    }

    public async componentDidMount(): Promise<void>
    {
        await this.handleLogin();
        this.setSelectedBuilding();
    }

    private setSelectedBuilding(): void
    {
        const userPreferences = this.local.getUserPreferences();
        const defaultBuildingId = userPreferences.SearchPrefs.DefaultBuilding;

        if (defaultBuildingId == null)
        {
            return;
        }

        const nodes = this.local.getNodeData();
        this.appState.set({ buildingId: defaultBuildingId });
    }

    private async handleLogin(): Promise<void>
    {
        try
        {
            let result: LoginResult;
            let securityProvider = this.local.getUserDetails().providerType ?? -1;
            switch (securityProvider)
            {
                case 0:
                    result = await this.handleNativeLogin();
                    break;

                case 1:
                    result = await this.handleActiveDirectoryLogin();
                    break;

                case 2:
                    result = await this.handleOktaLogin();
                    break;

                default:
                    this.handleUnknownLogin();
                    return;
            }

            const user = this.local.getUserDetails()
            const userDetail: IUserDetails = {
                ...user,
                displayName: result.displayName,
                email: result.userEmail,
                userRights: result.rightsData
            }
            this.local.setUserDetails(userDetail);
            this.local.setToken(result.token);
            await this.cacheData(result.rightsData);
            this.props.history.replace('/');
        }
        catch (error)
        {
            this.session.clear();
            this.local.clear();
            this.props.history.replace(`/login?reason=${LogOutReason[LogOutReason.LoginFailed]}`);
        }
    }

    private async handleNativeLogin(): Promise<LoginResult>
    {
        let userDetailsResponse = await apis.getUserDetails();
        let rightsResponse = await apis.getRightsList();

        let result: LoginResult =
        {
            token: this.local.getToken() ?? "",
            userEmail: (userDetailsResponse.data.mail || userDetailsResponse.data.userPrincipalName || ""),
            displayName: (userDetailsResponse.data.displayName || userDetailsResponse.data.mail || userDetailsResponse.data.userPrincipalName || ""),
            rightsData: rightsResponse.data,
        };

        return Promise.resolve(result);
    }

    private async handleActiveDirectoryLogin(): Promise<LoginResult>
    {
        let token: any = ""
        let keys = Object.keys(localStorage)
        let userEmail = "";

        for (let i = 0; i < keys.length; i++)
        {
            if (keys[i].includes('.net-idtoken'))
            {
                token = JSON.parse(localStorage.getItem(keys[i]) || '{}');
            }
            if (keys[i].includes('login.windows.net'))
            {
                let userObject = JSON.parse(localStorage.getItem(keys[i]) || '{}');
                if (userObject.username)
                {
                    userEmail = userObject.username;
                }
            }
        }

        if (Object.entries(token).length <= 2)
        {
            throw "Bad Active Directory token.";
        }

        let tabsResponse = await apis.getTabListAAD(token.secret);
        let account = this.msal.getAccount();

        let result: LoginResult =
        {
            token: token.secret,
            userEmail: userEmail,
            displayName: account?.name ?? "",
            rightsData: tabsResponse.data,
        }
        return Promise.resolve(result);
    }

    private async handleOktaLogin(): Promise<LoginResult>
    {
        let token = JSON.parse(localStorage.getItem('okta-token-storage') || '{}');
        let keys = Object.keys(localStorage)
        let userEmail = "";

        for (let i = 0; i < keys.length; i++)
        {
            if (keys[i] === 'okta-token-storage')
            {
                let tokenStorage = JSON.parse(localStorage.getItem('okta-token-storage') || '{}');
                if (tokenStorage?.idToken?.claims?.email)
                {
                    userEmail = tokenStorage.idToken.claims.email;
                }
            }
        }

        if (Object.entries(token).length === 0)
        {
            throw "Bad OKTA token.";
        }

        let tabsResponse = await apis.getTabListAAD(token.idToken.value);

        let result: LoginResult =
        {
            token: token.idToken.value,
            userEmail: userEmail,
            displayName: token.idToken.claims.name,
            rightsData: tabsResponse.data,
        }
        return Promise.resolve(result);
    }

    private handleUnknownLogin(): void
    {
        var timeleft = 2;
        var downloadTimer = setInterval(() =>
        {
            if (timeleft <= 0)
            {
                clearInterval(downloadTimer);
                this.handleLogin();
            }
            else
            {
                document.getElementById("countdown")!.innerHTML = '' + timeleft + '';
            }
            timeleft -= 1;
        }, 1000);
    }

    private async cacheData(rights: any): Promise<void>
    {
        // cache nodes
        let nodeResponse = await apis.getData({ endPoint: `${1}/Nodes` });
        this.local.setNodeData(nodeResponse.data);

        // cache user prefences
        const userPrefResponses = await apis.getData({ endPoint: `${1}/UserPreferences` });
        this.local.setUserPreferences(userPrefResponses.data ? userPrefResponses.data : userPrefResponses);

        // cache right list
        const ibssRightList = await apis.getRightList();
        this.local.setIbssRights(ibssRightList.data);

        // get API version
        const versionRes = await apis.getAPIVersion();
        const version = versionRes.data.Version ? versionRes.data.Version.split('.') : versionRes.data.version.split('.');
        this.local.setVersion(version[1]);

        if (rights.FLEX || rights.ONELENS360)
        {
            const buildingData = Helper.getAllBuildingsData();
            if (buildingData && buildingData.length > 0 && buildingData[0].Node_Id && buildingData[0].Name)
            {
                const sortedBuildingData = buildingData.sort((a: { Name: string; }, b: { Name: string; }) => a.Name > b.Name ? 1 : -1);
                this.session.setBuildingId(sortedBuildingData[0].Node_Id);
            }
        }
    }

    public render() 
    {
        return (
            <div>
                <div className="redirectContainer">
                    <div className="title">{this.labels.HubLabelRedirectionStatus}</div>
                    <div className="subtitle">{this.labels.HubLabelRedirectionSubtitle_part_1} <span id="countdown"></span></div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: any) =>
{
    return {
        currentPageTitle: state.currentPageTitle,
        lightModeTheme: state.lightModeTheme,
    };
};

export default connect(mapStateToProps)(Dashboard);

class LoginResult
{
    public token = "";
    public userEmail = "";
    public displayName = "";
    public rightsData: any;
}
