import { FormControl, Radio, RadioGroup, FormControlLabel } from '@mui/material';
import { ISpace } from '../../../Pages/Shared/Bookings/Edit/SelectSpaceDrawer';
import { Constants } from '../../../Common/Constants';
import { IbssComponent } from '../../Core/BaseComponent/IbssComponent';

class SwapSpaceCard extends IbssComponent<IProps,IState>
{
    render(): JSX.Element
    {
        const { space, floorName, selectedSpaceId, handleSpaceSelect } = this.props;

        return (
            <div key={space.id} style={{
                border: '1px solid #999999',
                borderRadius: '0.3rem',
                padding: '0.3rem',
                marginTop: '1rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
                boxSizing: 'border-box'
            }}>
                <div style={{
                    width: '70px',
                    height: '70px',
                    cursor: 'pointer',
                    flexShrink: 0,
                    marginRight: '1rem'
                }}>
                    <img className="img-fluid" alt={space.spaceType} src={space.imageURI ? space.imageURI : Constants.imagePaths.get(space.spaceType)} />
                </div>
                <div style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    minWidth: 0,
                    gap: '10px'
                }}>
                    <p style={{
                        fontSize: '1rem',
                        fontWeight: '700',
                        margin: '0 0 0.5rem 0',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                    }}>{space.name}</p>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '0.5rem',
                        flexWrap: 'nowrap'
                    }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <img src="/images/Sidebar_Icons/Active_icons/Floor.svg" alt="floor" style={{ width: '14px', marginRight: '0.25rem' }} />
                            <span style={{ fontSize: '0.75rem' }}>{floorName}</span>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <img src="/images/Sidebar_Icons/Active_icons/Zone.svg" alt="zone" style={{ width: '14px', marginRight: '0.25rem' }} />
                            <span style={{ fontSize: '0.75rem' }}>{space.zone}</span>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <img src="/images/User Logged in (Fill).svg" alt="Capacity" style={{ width: '14px', marginRight: '0.25rem' }} />
                            <span style={{ fontSize: '0.75rem' }}>{space.capacity}</span>
                        </div>
                    </div>
                </div>
                <FormControl style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginLeft: '1rem',
                    flexShrink: 0
                }}>
                    <RadioGroup
                        value={selectedSpaceId}
                        onChange={() => handleSpaceSelect(space.id)}
                    >
                        <FormControlLabel value={space.id} control={<Radio />} label="" />
                    </RadioGroup>
                </FormControl>
            </div>
        );
    }
}

export default SwapSpaceCard;

interface IProps
{
    space: ISpace;
    floorName: string;
    selectedSpaceId: string;
    handleSpaceSelect: (id: string) => void;
}

interface IState
{
}